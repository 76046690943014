<template>
  <div>
    <div class="p-banner mb-40">
      <img alt="" class="p-banner-bg" src="../../assets/img/service/banner.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{$t("Service.Name")}}</div>
        <div class="p-banner-sub-title">{{$t("Service.Name")}}</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title">{{$t("Service.Name")}}</div>
      <el-row :gutter="50" class="flex-warp" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="product-item" @click="$goPage('/service/page-detail?type=1')">
            <div class="product-item-img"><img alt="" src="../../assets/img/service/item-1.png"></div>
            <div class="service-item-name">
              <div>{{$t("Service.Process.Name")}}</div>
              <img alt="" src="../../assets/img/service/right-arrow.png">
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="product-item" @click="$goPage('/service/page-detail?type=2')">
            <div class="product-item-img"><img alt="" src="../../assets/img/service/item-2.png"></div>
            <div class="service-item-name">
              <div>{{$t("Service.Design.Name")}}</div>
              <img alt="" src="../../assets/img/service/right-arrow.png">
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="product-item" @click="$goPage('/service/page-detail?type=3')">
            <div class="product-item-img"><img alt="" src="../../assets/img/service/item-3.png"></div>
            <div class="service-item-name">
              <div>{{$t("Service.Integration.Name")}}</div>
              <img alt="" src="../../assets/img/service/right-arrow.png">
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="product-item" @click="$goPage('/service/page-detail?type=4')">
            <div class="product-item-img"><img alt="" src="../../assets/img/service/item-4.png"></div>
            <div class="service-item-name">
              <div>{{$t("Service.Delivery.Name")}}</div>
              <img alt="" src="../../assets/img/service/right-arrow.png">
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  // name: "我们的服务"
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
