<template>
  <div class="footer">
    <div class="container" v-if="$baseVal.isPc">
      <el-row class="close-info-box align-c" justify="space-between" type="flex">
        <el-col :lg="18" :md="18" :sm="24" :xl="18" :xs="24" class="close-info-left">
          <div v-animate-on-intersect class="close-info-nick">
            <img v-image-preview src="../assets/img/qr.png">
          </div>
          <div v-animate-on-intersect class="close-info-list-box">
            <div class="close-info-list">
              <span :style="$i18n.locale === 'en' ? 'width:90px':''">{{ $t("Footer.Telephone") }}</span>
              <i class="iconfont icon-dianhua"></i>
              <span class="big">023-67665596</span>
            </div>
            <div class="close-info-list">
              <span :style="$i18n.locale === 'en' ? 'width:90px':''">{{ $t("Footer.Phone") }}</span>
              <i class="iconfont icon-tel"></i>
              <span class="big">17353266067</span>
            </div>
            <div class="close-info-list">
              <span :style="$i18n.locale === 'en' ? 'width:90px':''">{{ $t("Footer.Email") }}</span>
              <i class="iconfont icon-youjian"></i>
              <span>service@mhkjcq.com</span>
            </div>
            <div class="close-info-list">
              <span :style="$i18n.locale === 'en' ? 'width:90px;flex-shrink:0':''">{{ $t("Footer.Address") }}</span>
              <i class="iconfont icon-dizhi"></i>
              <span>{{ $t("Footer.Location") }}</span>
            </div>
          </div>
        </el-col>
        <el-col :lg="6" :md="6" :sm="24" :xl="6" :xs="24" class="close-info-right">{{ $t("Footer.Welcome") }}</el-col>
      </el-row>
      <div v-animate-on-intersect class="close-info-bottom">
        <div class="box1" :class="$i18n.locale === 'en' ? 'footer-box1-en' : ''">{{ $t("Footer.Slogan") }}</div>
        <div class="box2"></div>
      </div>
    </div>
    <div class="container" v-else>
      <el-row class="close-info-box align-c" justify="space-between" type="flex">
        <el-col :lg="18" :md="18" :sm="24" :xl="18" :xs="24" class="close-info-left">
          <div v-animate-on-intersect class="close-info-nick">
            <img v-image-preview src="../assets/img/qr.png">
          </div>
          <div v-animate-on-intersect class="close-info-list-box" style="width: 200px">
            <div class="close-info-list"><i class="iconfont icon-dianhua"></i><span class="big">023-67665596</span>
            </div>
            <div class="close-info-list"><i class="iconfont icon-tel"></i><span class="big">17353266067</span>
            </div>
            <div class="close-info-list"><i
                class="iconfont icon-youjian"></i><span>service@mhkjcq.com</span></div>
            <div class="close-info-list"><i
                class="iconfont icon-dizhi"></i><span>{{ $t("Footer.Location") }}</span></div>
          </div>
        </el-col>
        <!--        <el-col :lg="6" :md="6" :sm="24" :xl="6" :xs="24" class="close-info-right">期待您的联系！</el-col>-->
      </el-row>
      <div v-animate-on-intersect class="close-info-bottom">
        <div class="box1">{{ $t("Footer.Slogan") }}</div>
        <div class="box2"></div>
      </div>
    </div>
    <div v-if="$baseVal.isPc" class="footer-btm">
      <div class="part1">
        <el-row class="container flex-warp" justify="space-between" type="flex">
          <el-col :lg="8" :md="9" :sm="24" :xl="5" :xs="24" class="footer-btm-left">
            <img alt="" src="../assets/img/common/logo2.png">
            <div>{{$t("Footer.ProfileOne")}}</div>
            <div>{{$t("Footer.ProfileTwo")}}</div>
          </el-col>
          <el-col :lg="15" :md="14" :sm="20" :xl="15" :xs="20">
            <div class="footer-service-line">
              <div class="footer-service">
                <router-link class="footer-service-title" to="/product">{{ $t("Footer.Technology") }}</router-link>
                <router-link to="/product/page-rrjj">{{ $t("Product.TypeOne.CardOne.Name") }}</router-link>
                <router-link to="/product/page-shjj">{{ $t("Product.TypeOne.CardTwo.Name") }}</router-link>
                <router-link to="/product/page-fzzl">{{ $t("Product.TypeOne.CardThree.Name") }}</router-link>
              </div>
              <div class="footer-service">
                <router-link class="footer-service-title" to="/product">{{ $t("Product.TypeTwo.Name") }}</router-link>
                <router-link to="/product/page-ldctjj">{{ $t("Product.TypeOne.CardOne.Name") }}</router-link>
                <router-link to="/product/page-qqsysw">{{ $t("Product.TypeOne.CardTwo.Name") }}</router-link>
                <router-link to="/product/page-sfhfczyhly">{{ $t("Product.TypeOne.CardThree.Name") }}</router-link>
              </div>
              <div class="footer-service">
                <router-link class="footer-service-title" to="/service">{{$t("Home.CardThree.Service")}}</router-link>
                <router-link to="/service/page-detail?type=1">{{$t("Service.Process.Name")}}</router-link>
                <router-link to="/service/page-detail?type=2">{{$t("Service.Design.Name")}}</router-link>
                <router-link to="/service/page-detail?type=3">{{$t("Footer.Integration")}}</router-link>
                <router-link to="/service/page-detail?type=4">{{$t("Service.Delivery.Name")}}</router-link>
              </div>
              <div class="footer-service">
                <router-link class="footer-service-title" to="/aboutUs">{{$t("AboutUs.Name")}}</router-link>
                <router-link to="/aboutUs">{{$t("Footer.Introduction")}}</router-link>
                <router-link to="/news">{{$t("Footer.News")}}</router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="part2">
        <div class="d-flex-between flex-warp container">
          <div>{{$t("Footer.Copyright")}}</div>
          <div class="d-flex align-c">
            <a href="https://beian.miit.gov.cn" target="_blank">{{$t("Footer.PoweredByOne")}} </a>
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50011302222017" target="_blank">
              <img alt="" src="../assets/img/common/footer-icon.png">{{$t("Footer.PoweredByTwo")}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="footer-btm-wap footer-mobile">
      <div class="footer-content">
        <div class="cont-img"><img alt="" src="../assets/img/common/logo.png"></div>
        <div class="text" :class="$i18n.locale === 'en' ? 'en-text' : ''">{{$t("Footer.ProfileOne")}}<br>{{$t("Footer.ProfileTwo")}}</div>
      </div>
      <div class="copy-right-mobile">
        <div class="text1">{{$t("Footer.Copyright")}}</div>
        <div class="text2" :class="$i18n.locale === 'en' ? 'en-text2' : ''">
          <a href="https://beian.miit.gov.cn" style="color: rgb(255, 255, 255); text-decoration: none;" target="_blank">{{$t("Footer.PoweredByOne")}} </a>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50011302222017" rel="noreferrer" class="text2-link"
             target="_blank">
            <img alt="" src="../assets/img/common/footer-icon.png" style="width: 14px; margin-right: 4px;">
            <span>{{$t("Footer.PoweredByTwo")}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    console.log('this.$i18n.local')
    console.log(this.$i18n.locale)
  }
}
</script>

<style scoped>

</style>
