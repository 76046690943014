<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner2.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{ menuType == 1 ? $t("Product.Melt.TabOne.Title") : menuType == 2 ? $t("Product.Melt.TabTwo.Title") : $t("Product.Melt.TabThree.Title")}}</div>
        <div class="p-banner-sub-title">{{ menuType == 1 ? $t("Product.Melt.TabOne.Title") : menuType == 2 ? $t("Product.Melt.TabTwo.Title") : $t("Product.Melt.TabThree.Title")}}</div>
      </div>
    </div>
    <div class="page-menu d-flex-center service-page-menu" >
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">{{$t("Product.Melt.TabOne.Name")}}</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">{{$t("Product.Melt.TabTwo.Name")}}</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">{{$t("Product.Melt.TabThree.Name")}}</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">{{$t("Product.Melt.TabOne.SubTitle")}}</div>
      <div class="page-text mb-15">{{$t("Product.Melt.TabOne.SubProfile")}}</div>
      <div class="preview-image mb-40" v-if="$i18n.locale === 'zh'"><img class="page-img" v-image-preview  v-animate-on-intersect src="../../assets/img/product/item2-1.png" alt=""></div>
      <div class="preview-image mb-40" v-else><img class="page-img" v-image-preview  v-animate-on-intersect src="../../assets/img/product/item2-1_en.png" alt=""></div>
      <div class="product-item-title">{{$t("Product.Melt.TabOne.Use")}}</div>
      <el-row class="flex-warp mb-40 radius-10" justify="space-between" type="flex"  v-animate-on-intersect>
        <el-col :lg="13" :md="13" :sm="13" :xl="13" :xs="24">
          <div class="preview-image"><img v-image-preview class="box-img" style="height: 100%" src="../../assets/img/product/item2-2.png"></div>
        </el-col>
        <el-col :lg="11" :md="11" :sm="11" :xl="11" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.VCPur")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.FECPur")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Polymer")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Separation")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Benzoic")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Industrial")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Chloroacetic")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Phosphoric")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Naphthalene")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Melt.TabOne.Acetonitrile")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Melt.TabOne.Feature")}}</div>
      <div class="img-list mb-40"  v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Melt.TabOne.Energy")}}</span>{{$t("Product.Melt.TabOne.EnergyDescribe")}}</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Melt.TabOne.Temperature")}}</span>{{$t("Product.Melt.TabOne.TemperatureDescribe")}}</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Melt.TabOne.Solvent")}}</span>{{$t("Product.Melt.TabOne.SolventDescribe")}}</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Melt.TabOne.Materials")}}</span>{{$t("Product.Melt.TabOne.MaterialsDescribe")}}</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Melt.TabOne.Purity")}}</span>{{$t("Product.Melt.TabOne.PurityDescribe")}}</div></div>
      </div>
    </div>
    <div v-else-if="menuType == 2" class="container">
      <div class="product-item-title">{{$t("Product.Melt.TabTwo.Summary")}}</div>
      <div class="grey-box page-text mb-40"  v-animate-on-intersect>{{$t("Product.Melt.TabTwo.SubProfile")}}</div>
      <div class="product-item-title">{{$t("Product.Melt.TabTwo.Structure")}}</div>
      <div class="page-text">{{$t("Product.Melt.TabTwo.StructureProfile")}}</div>
      <el-row class="flex-warp mb-40" v-animate-on-intersect justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Melt.TabTwo.OuterContainer")}}</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-1.png" alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Melt.TabTwo.Crystals")}}</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-2.png" alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Melt.TabTwo.Crystallization")}}</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-3.png" alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Melt.TabTwo.Assembly")}}</div>
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/product/item3-4.png" alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Melt.TabTwo.Features")}}</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabTwo.Strength")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabTwo.Design")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabTwo.Support")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabTwo.Shape")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabTwo.BoxDesign")}}</div>
      </div>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">{{$t("Product.Melt.TabThree.Summary")}}</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>{{$t("Product.Melt.TabThree.SubProfile")}}</div>
      <div class="product-item-title">{{$t("Product.Melt.TabThree.Equipment")}}</div>
      <div class="page-text">{{$t("Product.Melt.TabThree.EquipmentProfile")}}</div>
      <el-row class="flex-warp mb-40" v-animate-on-intersect justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Melt.TabThree.CrystallizationOne")}}</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/item4-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Melt.TabThree.CrystallizationThree")}}</div>
             <div class="preview-image"><img class="page-img" src="../../assets/img/product/item4-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Melt.TabThree.Advantages")}}</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabThree.Core")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabThree.Profitable")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Melt.TabThree.Extent")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "熔融结晶",
  data(){
    return{
      menuType:1
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
