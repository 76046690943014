<template>
  <div>
    <div class="p-banner mb-40">
      <img v-if="$baseVal.isPc" alt="" class="p-banner-bg" src="../assets/img/aboutUs/banner.png">
      <img v-else alt="" style="object-position: unset" class="p-banner-bg" src="../assets/img/aboutUs/banner_wap.jpg">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{$t("AboutUs.Name")}}</div>
        <div class="p-banner-sub-title">{{$t("AboutUs.Name")}}</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title">{{$t("AboutUs.Id")}}</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>
        {{$t("AboutUs.Profile")}}
      </div>
      <div class="product-item-title"> {{$t("AboutUs.Organizational")}}</div>
      <div class="preview-image mb-40" v-if="$i18n.locale === 'zh'"><img alt="" v-image-preview class="page-img " v-animate-on-intersect src="../assets/img/aboutUs/us1.png"></div>
      <div class="preview-image mb-40" v-else><img alt="" v-image-preview class="page-img " v-animate-on-intersect src="../assets/img/aboutUs/us1_en.png"></div>
      <div class="product-item-title">{{$t("AboutUs.Culture")}}</div>
      <el-row :gutter="40" v-animate-on-intersect class="flex-warp mb-25" justify="space-between" type="flex">
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <div class="aboutUs-item">
            <div class="img-item-title">{{$t("AboutUs.Mission")}}</div>
            <div class="grey-box page-text">{{$t("AboutUs.Advanced")}}</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <div class="aboutUs-item">
            <div class="img-item-title">{{$t("AboutUs.Vision")}}</div>
            <div class="grey-box page-text">{{$t("AboutUs.Provider1")}}
              <br>{{$t("AboutUs.Provider2")}}
              <br>{{$t("AboutUs.Provider3")}}
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <div class="aboutUs-item">
            <div class="img-item-title">{{$t("AboutUs.Values")}}</div>
            <div class="grey-box page-text">{{$t("AboutUs.Equality")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("AboutUs.History")}}</div>
      <div class="grey-box create-line-outer mb-40" v-animate-on-intersect>
        <div class="create-line" :class="$i18n.locale === 'en' ? 'create-line-en' : ''">
          <div class="create-line-x"></div>
          <div class="create-line-item">
            <div class="create-line-item-cont">
              <div class="name">{{$t("AboutUs.Year2021")}}</div>
              <div class="page-text f-bold" :class="$i18n.locale === 'en' ? 'page-text-en' : ''">{{$t("AboutUs.Molecular")}}</div>
            </div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-empty"></div>
          </div>
          <div class="create-line-item">
            <div class="create-line-empty"></div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-item-cont flex-col-end">
              <div class="name">{{$t("AboutUs.Year2022")}}</div>
              <div class="page-text f-bold" :class="$i18n.locale === 'en' ? 'page-text-en' : ''">{{$t("AboutUs.Catalytic")}}</div>
            </div>
          </div>
          <div class="create-line-item">
            <div class="create-line-item-cont">
              <div class="name">{{$t("AboutUs.Year2023")}}</div>
              <div class="page-text f-bold" :class="$i18n.locale === 'en' ? 'page-text-en' : ''">{{$t("AboutUs.Continuous")}}</div>
            </div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-empty"></div>
          </div>
          <div class="create-line-item">
            <div class="create-line-empty"></div>
            <div class="create-line-item-center"><div class="dot"></div></div>
            <div class="create-line-item-cont flex-col-end">
              <div class="name">{{$t("AboutUs.Year2024")}}</div>
              <div class="page-text f-bold" :class="$i18n.locale === 'en' ? 'page-text-en' : ''">{{$t("AboutUs.Realize")}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-item-title">{{$t("AboutUs.Honors")}}</div>
      <div class="btm-swiper"  @touchstart="stopAutoplay" @touchend="startAutoplay" @mouseenter="stopAutoplay" @mouseleave="startAutoplay">
        <div class="prev"  v-if="$baseVal.isPc" ></div>
        <div class="next"  v-if="$baseVal.isPc" ></div>
        <swiper  ref="aboutUsSwiper" :options="swiperOption" class="preview-swiper">
          <swiper-slide >
            <div v-if="$i18n.locale === 'en'" class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-3.png" alt=""></div>
            <div v-else class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-4.png" alt=""></div>
          </swiper-slide>
<!--          <swiper-slide v-else><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-4.png" alt=""></div></swiper-slide>-->
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-l-1.png" alt="" style="background: #FFF9EB"></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book-l-2.png" alt="" style="background: #FFF9EB"></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book1.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book2.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book3.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book4.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book5.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book6.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book7.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book8.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book9.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book10.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book11.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book12.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book13.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book14.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book15.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book16.jpg" alt=""></div></swiper-slide>
          <swiper-slide><div class="preview-image"><img v-image-preview src="../assets/img/aboutUs/book17.png" alt=""></div></swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
import 'swiper/swiper-bundle.css';

export default {
  // name: "关于我们",
  data(){
    return{
      swiperOption:{
        slidesPerView:'auto',
        loop:true,
        spaceBetween:this.$baseVal.isPc ? 30 : 10,
        speed:5000,
        autoplay:{
          delay: 30,//1秒切换一次
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        },
        freeMode: true,
        navigation:{
          nextEl:'.next',
          prevEl:'.prev'
        }
      }
    }
  },
  methods:{
    stopAutoplay() {
        this.$refs.aboutUsSwiper.swiper.autoplay.stop();
    },
    startAutoplay() {
        this.$refs.aboutUsSwiper.swiper.autoplay.start();
    }
  }
}
</script>

<style>
@import "../assets/css/product.css";
</style>
