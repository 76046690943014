<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner4.png">
      <div class="container">
        <div class="p-banner-title">{{ menuType == 1 ? $t("Product.Distillation.TabOne.Title") : menuType == 2 ? $t("Product.Distillation.TabTwo.Title") : $t("Product.Distillation.TabThree.Title") }}</div>
        <div class="p-banner-sub-title">{{ menuType == 1 ? $t("Product.Distillation.TabOne.Title") : menuType == 2 ? $t("Product.Distillation.TabTwo.Title") : $t("Product.Distillation.TabThree.Title") }}</div>
      </div>
    </div>
    <div class="page-menu d-flex-center" :class="$i18n.locale === 'en' ? 'dis-center' : ''">
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">{{$t("Product.Distillation.TabOne.Name")}}</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">{{$t("Product.Distillation.TabTwo.Name")}}</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">{{$t("Product.Distillation.TabThree.Name")}}</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">{{$t("Product.Distillation.TabOne.SubTitle")}}</div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="21" :md="21" :sm="21" :xl="21" :xs="24">
          <div class="dot-box">
            <div class="dot-list">{{$t("Product.Distillation.TabOne.MolecularDistillationProcess")}}</div>
            <div class="dot-list">{{$t("Product.Distillation.TabOne.Liquid")}}</div>
            <div class="dot-list">{{$t("Product.Distillation.TabOne.Evaporation")}}</div>
            <div class="dot-list">{{$t("Product.Distillation.TabOne.Fly")}}</div>
            <div class="dot-list">{{$t("Product.Distillation.TabOne.Moleccondenese")}}</div>
          </div>
        </el-col>
        <el-col :lg="3" :md="3" :sm="3" :xl="3" :xs="24">
          <div class="preview-image" v-if="$i18n.locale === 'zh'"><img class="box-img" v-image-preview style="height: 100%" src="../../assets/img/product/fzzl1-1.png"></div>
          <div class="preview-image" v-else><img class="box-img" v-image-preview style="height: 100%" src="../../assets/img/product/fzzl1-1_en.jpg"></div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Distillation.TabOne.Application")}}</div>
      <el-row class="flex-warp mb-40 radius-10" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :lg="13" :md="13" :sm="13" :xl="13" :xs="24">
          <div class="preview-image"><img class="box-img" v-image-preview style="height: 100%" src="../../assets/img/product/fzzl1-2.png"></div>
        </el-col>
        <el-col :lg="11" :md="11" :sm="11" :xl="11" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Volatile")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Fractions")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Purification")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Pharmaceutical")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Sulfoxide")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Residues")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Refined")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Distillation.TabOne.Oil")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Distillation.TabOne.TechnicalFeatures")}}</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Distillation.TabOne.Operation")}}</span>{{$t("Product.Distillation.TabOne.OperationDescribe")}}</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Distillation.TabOne.Temperature")}}</span>{{$t("Product.Distillation.TabOne.TemperatureDescribe")}}</div></div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt=""><div><span>{{$t("Product.Distillation.TabOne.Time")}}</span>{{$t("Product.Distillation.TabOne.TimeDescribe")}}</div></div>
      </div>
    </div>
    <div v-else-if="menuType == 2" class="container">
      <div class="product-item-title">{{$t("Product.Distillation.TabTwo.Summary")}}</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>{{$t("Product.Distillation.TabTwo.SubProfile")}}</div>
      <div class="product-item-title">{{$t("Product.Distillation.TabTwo.Structure")}}</div>
      <div class="page-text">{{$t("Product.Distillation.TabTwo.StructureProfile")}}</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Distillation.TabTwo.Cylinder")}}</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Distillation.TabTwo.Board")}}</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Distillation.TabTwo.Interior")}}</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-3.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Distillation.TabTwo.Assembly")}}</div>
            <div class="preview-image"> <img class="page-img" src="../../assets/img/product/shjj2-4.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Distillation.TabTwo.Features")}}</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Distillation.TabTwo.Design")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Distillation.TabTwo.Polymer")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Distillation.TabTwo.Life")}}</div>
      </div>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">{{$t("Product.Distillation.TabThree.Summary")}}</div>
      <div class="grey-box page-text mb-40"  v-animate-on-intersect>{{$t("Product.Distillation.TabThree.SubProfile")}}</div>
      <div class="product-item-title">{{$t("Product.Distillation.TabThree.Equipment")}}</div>
      <div class="page-text">{{$t("Product.Distillation.TabThree.EquipmentProfile")}}</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Distillation.TabThree.Mounted")}}</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/fzzl2-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Distillation.TabThree.System")}}</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/fzzl2-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Distillation.TabThree.Advantages")}}</div>
      <div class="img-list mb-40"  v-animate-on-intersect>
        <div class="page-text f-bold">{{$t("Product.Distillation.TabThree.Molecular")}} </div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Distillation.TabThree.Stable")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Distillation.TabThree.Determine")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Distillation.TabThree.Adopt")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Distillation.TabThree.Characteristics")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "分子蒸馏",
  data(){
    return{
      menuType:1
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
