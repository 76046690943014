export default {
    Header: {
        Home: 'Home',
        Product: 'Productions',
        Service: 'Severices',
        News: 'News',
        About: 'About Us',
        Title: 'CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'
    },
    Home: {
        CardOne: {
            Product: 'Productions',
            Slogan: 'Stable performance unit technology and equipment',
            More: 'MORE'
        },
        CardTwo: {
            Product: 'Productions',
            Slogan: 'Mature and reliable advanced process technology',
            More: 'MORE'
        },
        CardThree: {
            Service: 'Severices',
            Slogan: 'Comprehensive professional services from laboratory to industrial-scale facilities',
            More: 'MORE'
        },
    },
    Product: {
        product: 'Our Products',
        TypeOne: {
            Name: 'Unit Technology and Equipment',
            CardOne: {
                Name: 'Melting Crystallization',
                Slogan: 'Production scheme of high purity chemicals',
            },
            CardTwo: {
                Name: 'Molecular Distillation',
                Slogan: 'High boiling point heat sensitive material separation tool',
            },
            CardThree: {
                Name: 'Sublimation Crystallization',
                Slogan: 'Special high-purity separation solutions',
            },
        },
        TypeTwo: {
            Name: 'Process Engineering',
            CardOne: {
                Name: 'Lithium Battery Additives',
                Slogan: 'Industry-leading mature and reliable technology',
            },
            CardTwo: {
                Name: 'Hydrocyanic Acid Derivatives',
                Slogan: 'New technology for mature products',
            },
            CardThree: {
                Name: 'Three Waste Recycling Technology',
                Slogan: 'New solutions to solve the difficult problems',
            },
        },
        Melt: {
            Name: 'Melting Crystallization Technology',
            TabOne: {
                Name: 'Technical Introduction',
                Title: 'Melting Crystallization Technology',
                SubTitle: 'Know-why',
                SubProfile: 'Molten crystallization is a method of material separation and purification according to the different solidification points between the substances to be separated. It is a clean separation process of organic matter with low energy consumption. It can efficiently separate special materials such as hydrocarbons, isomers and thermosensitive materials with the same boiling point. The preparation of ultrapure substances is a unique advantage of molten crystallization. The melting crystallization obtains the high purity product through the operation of cooling crystallization, heating and sweating, and heating and melting.',
                //典型应用
                Use: 'Typical Application',
                VCPur: 'Subcarbonate (VC) distilled to 95% purity was purified to 99.99%',
                FECPur: '99% of the fluorinated vinyl carbonate (FEC) was purified to 99.95%',
                Polymer: 'The polymer resist content in acrylic acid was reduced from 200ppm to 20ppm',
                Separation: 'Separation of a mixture of p-cresol / m-cresol',
                Benzoic: 'The industrial grade benzoic acid was purified to food grade benzoic acid',
                Industrial: 'The industrial grade benzoic acid was purified to the essence and flavor grade',
                Chloroacetic: 'Preparation of high-purity chloroacetic acid',
                Phosphoric: 'Production of the electronic-grade phosphoric acid',
                Naphthalene: 'Purification of naphthalene oil phenol vinyl carbonate',
                Acetonitrile: 'Production of the chromatographic-grade acetonitrile',
                //技术特点
                Feature: 'Technical Feature',
                Energy: 'Energy saving: ',
                EnergyDescribe: 'the molten heat is only 1 / 7~1 / 3 of the heat of vaporization, and the comprehensive energy consumption of melting crystallization is only 10%~30% of the energy consumption of distillation',
                Temperature: 'Low temperature operation: ',
                TemperatureDescribe: 'atmospheric pressure, low temperature operation, very suitable for the separation and purification of thermal sensitive substances, dilute solution, high boiling point material',
                Solvent: 'No solvent introduction: ',
                SolventDescribe: 'melting crystallization does not need additional solvent, which is to avoid the solvent pollution to the product, and reduce the solvent recovery process',
                Materials: 'Suitable for special materials: ',
                MaterialsDescribe: 'suitable for similar boiling point, isomer, chiral substances and other conventional distillation is extremely difficult to separate the system',
                Purity: 'High product purity: ',
                PurityDescribe: 'molten crystallization product purity can generally reach more than 99.9%, the highest to 99.999%'
            },
            TabTwo: {
                Name: 'Core Equipment',
                Title: 'Static Plate-type Molten Junction',
                Summary: 'Summary',
                SubProfile: 'The plate melt crystallization is the core equipment of the static layer melt crystallization unit, and the specification is from 1L to 30m3. No matter the application size, Minheng Technology can meet the requirements. We will design according to your material composition and separation requirements to match your process with the best equipment performance.',
                Structure: 'Structure',
                StructureProfile: 'The plate melt crystallization is composed of outer box, crystal plate and support plate, no transmission parts, static intermittent batch operation, maintenance free, easy operation, can be competent for the high purity production requirements of various chemicals.structural composition:',
                OuterContainer: 'outer container',
                Crystals: 'Crystals',
                Crystallization: 'Crystallization plate group',
                Assembly: 'Assembly finished product',
                Features: 'Design Features',
                Strength: 'The crystal plate is a high strength sheet, the equipment weight is light, and the foundation load required for installation is small',
                Design: 'The unique flow channel design ensures that the temperature field of the crystallization plate is uniform, and the temperature difference between different positions of the crystallization plate is <0.1℃, which effectively ensures the separation efficiency',
                Support: 'The support plate is set between the crystallization plates to strengthen the heat transfer and make the support crystal layer does not collapse when sweating, and improve the sweating efficiency',
                Shape: 'The ed cuboid shape, high space use efficiency, installation, repair and maintenance is extremely convenient',
                BoxDesign: 'Match the crystallization requirements of the outer box design, no crystallization dead area, convenient feeding, discharging thoroughly',
            },
            TabThree: {
                Name: 'Complete Equipments',
                Title: 'Complete Set of Melting Crystallization Equipment',
                Summary: 'Summary',
                SubProfile: 'Minheng Technology is committed to providing the most efficient and reliable sets of equipment to ensure the best performance of your crystallization. In order to obtain the best performance, from the design and process matching crystallizer, to the supporting utilities and nitrogen protection system, we solve all the design and manufacturing problems with rigorous, scientific principles, and achieve a complete integration of the system.',
                Equipment: 'Complete Set of Equipment',
                EquipmentProfile: 'The complete set of molten crystallization equipment is composed of secondary subunits such as crystallization, storage and transportation system, utilities, nitrogen protection system, pipeline valve, electrical instrument, control system, ladder platform, insulation and so on. According to the scale, it can be divided into skid mounted equipment or on-site installation system. The skid-mounted equipment will be assembled and tested in the production workshop and then transported to the customer site for secondary assembly and commissioning; the field installation system will be assembled and tested at the customer site.',
                CrystallizationOne: 'First-level crystallization complete set of skid-mounted equipment',
                CrystallizationThree: 'Three-level crystallization site installation system',
                Advantages: 'Advantages of Complete Equipment',
                Core: 'Although the design of crystallizer is the core, the auxiliary system is also very critical for the efficient and stable operation of the equipment. The complete set of equipment maximizes the use of Minhengs professional knowledge and rich experience in the auxiliary system of molten crystallization equipment, which can ensure the best performance when the customer equipment is put into operation',
                Profitable: 'Skid-mounted equipment or field installation system services will maximize the human resources and energy of the project savings, customers can use the relevant resources in the most profitable place',
                Extent: 'To the greatest extent, avoid the lack of design and hardware matching, complex multi-interface handover, uncertain delivery and extended project construction period caused by the single supply mode of each supplier',
            }
        },
        Distillation: {
            Name: 'Molecular Distillation Technology',
            TabOne: {
                Name: 'Technical Introduction',
                Title: 'Molecular Distillation',
                //技术原理
                SubTitle: 'Know-why',
                MolecularDistillationProcess: "Molecular distillation is a special liquid-liquid separation technique that operates under high vacuum and allows the liquid to be separated at temperatures well below its boiling point. It uses the principle of the average free range of molecular motion, heats the liquid mixture slightly, so that the liquid molecules escape from the liquid surface, light molecules fall on the condensing surface and heavy molecules return, thus achieving the separation of the liquid. The molecular distillation process is completed in the following four steps:",
                Liquid: '① The molecules diffuse from the liquid phase to the evaporation surface',
                Evaporation: '② Free evaporation of the molecules on the liquid-phase surface',
                Fly: '③ The molecules fly from the evaporation surface to the condensing surface',
                Moleccondenese: '④ Moleccondense on the condensing surface',
                //典型应用
                Application: 'Typical Application',
                Volatile: 'Remove the volatile components in the resin and the polymer',
                Fractions: 'Remove low boiling fractions in natural wax',
                Purification: 'Concentrate and purification of lactic acid, glycerol, aromatic amine, fatty acid amide polybenzene compounds',
                Pharmaceutical: 'Isoland purified dimeric fatty acids, pesticides, disinfectants, herbicides, high carbon alcohols, paraffin, and pharmaceutical intermediates',
                Sulfoxide: 'Sulfoxide, dimethylsulfoxide, dimethylformamide, and dimethylacetamide were recovered',
                Residues: 'Chemdeodordeodorization and remove pesticide residues',
                Refined: 'Refined acrylic acid and acrylate, carbonate',
                Oil: 'Purified rose oil, mangura oil, eucalyptus oil, citronella oil, orange oil, violone and other aromatic substances',
                //技术特点
                TechnicalFeatures: "Technical Features",
                Operation: 'High vacuum of operation: ',
                OperationDescribe: 'because the spacing between the hot and cold surfaces of molecular distillation is smaller than the average free range of light molecules, the actual vacuum of the evaporation surface is higher than the traditional vacuum distillation, and the operating pressure can reach the order of 0.1~1Pa',
                Temperature: 'Low operating temperature: ',
                TemperatureDescribe: 'molecular distillation depends on the difference of the average free path of molecular motion, and does not need to reach the atmospheric boiling point of the material',
                Time: 'From the process of the material heating time is short: ',
                TimeDescribe: 'molecular distillation in the evaporation process, the material is forced to form a very thin liquid film, and is directional push, so that the liquid in the separator stay time is very short, especially light molecules, once escape immediately condensation, the heating time is shorter, the thermal damage of the material is very small',
            },
            TabTwo: {
                Name: 'Core Equipment',
                Title: 'Molecular Distillation',
                Summary: 'Summary',
                SubProfile: 'It is difficult to obtain the ideal results of the conventional distillation equipment by separating the products from the distillation or purification of high boiling point, high viscosity and thermal sensitivity. The specially designed molecular distillation machine is a powerful tool to solve such problems. The molecular distillation machine combines heating and condensation together, and distributes the material into the heating surface continuously through the film-forming scraper, so that the evaporation components can quickly escape from the heating surface and be collected by condensation on the condensation surface. This feature of molecular distillation allows it to effectively treat sticky materials and is suitable for fouling applications.',
                Structure: 'Structure',
                StructureProfile: 'The molecular distillation is composed of outer cylinder with heating jacket, inner cooler, scraper scraper, cloth liquid and sealing structure, drive motor, etc. High precision transmission and mechanical seal design, overall processing and molding of the evaporation cylinder, unique liquid distributor and splash-proof equipment are the key to ensure the long-term stable operation of the equipment.',
                Cylinder: 'A clean outer cylinder',
                Board: 'Anti base board',
                Interior: 'The interior cooler',
                Assembly: 'Assembly finished product',

                Features: 'Design Features',
                Design: 'According to the specific material system for equipment design, truly achieve the best match of the process and equipment',
                Polymer: 'For the system with high bypolymer content and large viscosity, the auxiliary discharging mechanism can be set to assist discharging',
                Life: 'Unique film forming structure design, low speed can achieve good film forming, greatly prolong the service life of scraper blade, mechanical seal and drive bearing',
            },
            TabThree: {
                Name: 'Complete Equipment',
                Title: 'Molecular Distillation Complete Equipment',
                Summary: 'Summary',
                SubProfile: 'A design and manufacture excellent molecular distillation, if there is no suitable auxiliary system, also difficult to play the due performance, sensitive constant technology using its professional skills and rich experience, can provide you from utilities to vacuum system integration of complete sets of equipment, let you get the best performance of the molecular distillation system.',
                Equipment: 'Complete Set of Equipment',
                EquipmentProfile: 'The complete set of molecular distillation equipment is composed of molecular distillation unit, light and light phase receiving tank, cold trap, vacuum unit and other equipment, and secondary sub-units of pipeline valve, electrical instrument, ladder platform, thermal insulation and heat tracing. It can be divided into skid-mounted equipment or field installation system. The skid-mounted equipment will be assembled and tested in the production workshop and transported to the customer site, and the site installation system will be assembled and tested at the customer site.',
                Mounted: 'Sk-mounted equipment for molecular distillation',
                System: 'Molecular distillation field installation system',

                Advantages: 'Advantages of Complete Equipment',
                Molecular: 'The design and manufacturing of molecular distillation equipment involves many aspects, including preheating, pre-degassing, single-stage evaporation or multistage evaporation, vacuum, utilities, product and waste disposal. Minhengs customization will ensure that the entire system is optimal. Details include:',
                Stable: 'Stable loading and flow control under high vacuum conditions',
                Determine: 'Determine the evaporation stages according to the material characteristics, production capacity and separation requirements, and distribute the evaporation rate between various levels',
                Adopt: 'Adopt the appropriate pretreatment process, which can reduce the area requirements for molecular distillation heating and cooling and save the system investment',
                Characteristics: 'According to the material characteristics, match the correct vacuum system and exhaust gas treatment equipment, to achieve a long cycle of stable operation',
            },
        },
        Crystallization: {
            Name: 'Sublimation Crystallization Technology',
            TabOne: {
                Name: 'Technical Introduction',
                Title: 'Sublimation Crystallization Technology',
                SubTitle: 'Know-why',
                SubProfile: 'Sublimation crystallization refers to the process in which solid substance is directly transformed into gaseous state without liquid state and gaseous substance is directly changed into solid state without liquid state. It includes two processes: solid sublimation and gas condensation. When the solid component to be separated has a significant difference in the partial pressure difference in the gas phase, and each component is relatively stable to the heat to be separated, the easily sublimation component is transformed into a gas state to evaporate from the solid state, and the evaporated gas is cooled, and the purified crystal of the easily sublimation component can be obtained.',
                //典型应用
                Application: 'Typical Application',
                Iodine: 'Isonemental iodine was recovered from the iodine-containing waste solution',
                Sublimation: 'The crude sulfur sublimation crystallization is prepared for tires, hose and rubber plate',
                Purified: 'The crude naphthalene was purified after sublimation to obtain 98.5% of the refined naphthalene purity',
                Camphor: 'Sublimation purified rough camphor to over 99.5% purity',
                Metal: 'Refined and purified metal halide ferric trichloride (FeCl3), aluminum trichloride (AlCl3), metallocene and other products',
                Melamine: 'Continuous products from melamine and phenic anhydride',
                Organic: 'Refined and purified organic photoelectric materials BCP, Alq 3, Bpy-OXD, B5T, BP2T and other products',
                Separation: 'Separation and recovery or refined purification of salicylic acid, benzoquinone, benzoic acid, terephthalic acid and other products',

                //技术特点
                TechnicalFeatures: "Technical Feature",
                chemical: 'Do not use chemical solvents, do not produce waste liquid, meet the requirements of green chemical industry',
                substances: 'High purity substances can be obtained by using the sublimation characteristics of solid substances',
                quality: 'High-quality resolvable single crystal can be obtained'
            },
            TabTwo: {
                Name: 'Core Equipment',
                Title: 'Sublimation Crystallization Equipment',
                Summary: 'Summary',
                SubProfile: 'There are three main processes of sublimation crystallization, namely, atmospheric pressure sublimation, vacuum sublimation and carrying gas sublimation. There are not many substances that can sublimate under atmospheric pressure, and most substances can only be sublimated under vacuum or carrier gas condition, and vacuum sublimation is the most energy saving. Therefore, we have developed sublimator and condenser suitable for vacuum continuous sublimation, which can realize continuous sublimation and purification under vacuum condition.',

                Sublimator: 'sublimator',
                Performance: 'Performance Characteristics',
                Continuously: 'Can be continuously and fully closed production',
                Short: 'Short material residence time',
                Residue: 'There is less product residue in the final residue',
                Efficiency: 'Excellent mixing efficiency',
                Heating: 'The heating surface can be self-cleaned',
                Energy: 'Low energy consumption',
                Flexibility: 'Its flexibility is achieved through the replaceable rotor elements',

                Condenser: 'The Condenser',
                Characteristics: 'Performance Characteristics',
                Continuous: 'Continuous condensation of water, continuous extraction',
                Stably: 'It can operate stably for a long time under-30~250℃, 10~1000Pa operation conditions',
                Degree: 'High degree of automation, one-key start and stop',
                Crystal: 'Product crystal controllable (sheet, powder)',
                Directed: 'Directed condensation, no risk of crystallization blockage',
            },
            TabThree: {
                Name: 'Complete Equipments',
                Title: 'Sublimation Crystallization Complete Equipments',
                Summary: 'Summary',
                SubProfile: 'As a solvent-free crystallization method, sublimation crystallization has great advantages in the production of high purity products, screening of new crystal forms, and preparation of high quality single crystal. Sublimation crystallization includes two processes: sublimation and condensation, combining sublimator and condenser, supporting auxiliary equipment, electrical instruments and pipeline valves, so as to get a complete set of equipment to meet the requirements of quality, yield, energy consumption and other aspects of extensive.',

                Equipment: 'Complete Set of Equipment',
                EquipmentProfile: 'The complete set of sublimation crystallization equipment is composed of secondary subunits, including sublimation device, condenser, feed system, discharge system, utilities, nitrogen protection system, pipeline valve, electrical instrument, control system, ladder platform, insulation and heat tracing and so on. According to the scale, it can be divided into skid-mounted equipment or on-site installation system. The skid-mounted equipment will be assembled and tested in the production workshop and then transported to the customer site for secondary assembly and commissioning; the field installation system will be assembled and tested at the customer site.',
                Sublimation: 'Sublimation crystallization complete skid loading equipment',

                Advantages: 'Advantages of Complete Equipment',
                Separation: 'Whether it is the condensation crystallization separation of synthetic materials, or the sublimation crystallization purification of solid materials, the matching auxiliary system is the key to the long-term stable operation of the device. The complete set of equipment can allow you to obtain the production device with process performance guarantee in one step',
                Skid: 'Skid equipment or field installation system services will maximize the human resources and energy you invest into the project that you can use where they is most profitable',
                Extent: 'To the greatest extent, avoid the lack of design and hardware matching, complex multi-interface handover, uncertain delivery and extended project construction period caused by the single supply mode of each supplier',
            },
        },
        Lithium: {
            Name: 'Lithium Battery Additives',
            SubName: 'In the lithium battery additive series of products, we provide the following products of the process technology package services',
            CEC: 'Vinyl carbonate (CEC)',
            VC: 'Vinyl carbonate (VC)',
            FEC: 'Fluorinated vinyl carbonate (FEC)',
            ES: 'Vinyl sulfite (ES)',
            DTD: 'Vinyl Sulfate (DTD)',
        },
        Hydrocyanic: {
            Name: 'Hydrocyanic Acid Derivative Series of Products',
            SubName: 'In hydrocyanic acid derivative products, we provide process technology package services for the following products',
            A3: '(S) -4-Chloro-3-hydroxybutyl nitrile (A3)',
            A4: '(S) -4-chloride-3-hydroxybutyrate ethyl ester (A4)',
            A5: '(R) -4-cyanide-3-hydroxybutyrate ethyl ester (A5)',
        },
        Waste: {
            Name: 'Three Waste or By-product Resource Utilization Series of Products',
            SubName: 'In the three waste or by-product resource utilization series of products, we provide the following products process technology package services',
            Calcium: 'High purity calcium carbonate was prepared from calcium carbide slag',
        },
    },
    Service: {
        Name: 'Our Services',
        Process: {
            Name: 'Process Development & Testing',
            Overview: 'Overview',
            Profile: 'There are usually multiple methods to choose from for any particular separation system and separation task, and we can obtaine the  best solution with the help of high-quality development studies and tests.',
            Development: 'Development & Research',
            DevelopmentProfile: 'Our development& research are based on expertise, experience and years of accumulated data.With the help of advanced process simulation tools, we can compare and analyze the investment, energy consumption, product quality and operating costs of various possible scenarios in order to obtain the ideal solution. Through testing and experiments in the R&D center,We will obtain data that cannot be obtained through open literature and existing databases.',
            Research: 'Research Results',
            ResearchProfile: 'The results of the development will form a preliminary technical proposal that will provide you with all the information you need to make decisions about the next steps in your project, including the separation technology , product purity, energy consumption and investment. Technical solutions typically contain the following information, which we will provide to you after the development is complete.',
            Design: 'Design foundations and assumptions',
            Process: 'Process description',
            Diagram: 'Process flow diagram',
            Data: 'Key process data',
            Energy: 'Energy consumption',
            Equipment: 'List of key equipment',
            Recommend: 'Recommend',

            Capabilities: 'Development Capabilities',
            CapabilitiesProfile: 'Our R&D laboratory is located in Chongqing International Biological City.The laboratory is equipped with reaction kinetics, reaction thermodynamics and other reaction development equipment and molecular distillation, rectification, extraction, melt crystallization, sublimation crystallization and other separation and testing equipment.We have the ability to develop the whole process from the laboratory to the  industrial equipment.',
            Laboratory: 'Process Development Laboratory',
            Analytical: 'Analytical  laboratory',
            Separation: 'Separation testing equipment',
            FixedBed: 'Fixed-bed catalytic reaction experimental setup',
            PilotProfile: 'We have pilot tests, and a industrialization demonstration base which are located in Danzhai Industrial Park, Guizhou. The industrialization demonstration base has relatively complete public works and sewage treatment systems, which can easily and quickly implement pilot test and industrialization demonstration operation.',
            Pilot: 'Pilot plant',
            Nitrogen: 'Nitrogen generation system',
        },
        Design: {
            Name: 'Engineering Design',
            Overview: 'Overview',
            Profile: 'Whether you are providing a critical specialized process equipment or a complete process plant, we can provide you with basic engineering packages and detailed engineering documentation according to your requirements.',
            Basic:'Basic Engineering Design',
            BasicProfile:'The basic engineering design package will provide the necessary process technical information for the construction of a new plant, and the content and depth of the basic engineering design package will be determined according to the specific requirements of the customer. Our basic engineering packages typically include:',
            Description:'Process Description',
            Diagram:'Process Flow Diagram (PFD)',
            Balance:'Mass balance&energy balance',
            Utilities:'List of Utilities',
            Plumbing:'Plumbing & Instrumentation Diagram (PID)',
            Facilities:'Facilities Data',
            Instrument:'Instrument data Sheet',
            Control:'Process Control Description',
            Preliminary:'Preliminary equipment layout drawings',
            Operating:'Preliminary operating manuals',
            Detailed:'Detailed Engineering Design',
            DetailedProfile:'When we provide you with a complete process unit, we will simultaneously provide you with a detailed engineering design file, which usually includes the following:',

            Specialization:'(1)Process Specialization',
            Instrumentation:'Plumbing & Instrumentation Diagram (PID)',
            Utility:'Utility Piping and Instrumentation Diagram (UID)',
            Mass:'Mass Balance Chart',
            UtilityBalance :'Utility Balance Diagram (UFD)',
            PID:'Plumbing & Instrumentation Diagram (P& ID)',
            ProcessDescription:'Process description',
            FacilitiesList:'Facilities  list (material, model, specification, weight, size)',
            Pipelines:'List of pipelines (including pressure pipe identification)',
            FacilitiesData:'Facilities data',
            Equipment:'Equipment load tables',
            Relationship:'Interlocking relationship tables',


            EquipmentFacilities:'(2)Equipment&facilities ',
            Drawings:'Equipment outline drawings (including assembly drawings, parts drawings, pre-welded parts.)',
            Strength:'Strength calculations',
            DiagramDevices:'Diagram of of the thermal insulation devices',
            EquipmentData:'Dynamic equipment data sheets',
            Complete:'Complete sets of equipment data sheets',

            Electrical:'(3)Electrical Facilities',
            EquipmentList:'Electrical equipment list',
            SchematicDiagram:'Electrical schematic diagram',
            WiringDiagram:'electrical equipment wiring diagram',
            Location:'grounding point location diagram',
            Cable:'cable table and wiring diagram',
            Lighting:'device lighting design',
            Comprehensive:'comprehensive material list',
            Information:'Information about inverter equipment (e.g. motor speed, reducer deceleration)',

            InstrFacilities:'(4)Instrumentation Facilities',
            InstrumentsList:'List of instruments (including meter number, model, measuring range)',
            Automatic:'Description of the automatic control scheme',
            InterLocking:'Automatic interlocking process description',
            DCS:'DCS I/O ',
            Layout:'Instrument piping wiring layout diagram',
            Loop:'Instrument loop diagram',
            Power:'Diagram of the power supply system',
            Grounding:'Diagram of the instrument grounding system',
            Toxic:'Distribution map of flammable and toxic gas alarms',
            Interlocks:'List of alarms and interlocks',
            Material:'Comprehensive Material list',

            PlumbingLayout:'(5)Plumbing Facilities and Layout',
            MaterialList:'Material list',
            Platform:'Diagram of the operating & repair platform , inclined ladder',
            Drawing:'Equipment layout drawing',
            Building:'Diagram of building conditions',
            Integrative:'Integrative material list',
            DataSheets:'Valve datasheets',
            Hanger:'Pipe support and hanger list',
            Pipe:'Diagram of pipe support and hanger',
            Tracing:'Pipe piping diagrams',
            Heat:'Pipe heat tracing',
            HeatTrace:'Diagram of the heat trace piping system',
            InsulationSheet:'Insulation sheet',
            Safety:'Distribution map of safety showers and eyewashes',
        },
        Integration: {
            Name: 'Equipment Manufacturing and Integration',
            Overview: 'Overview',
            Profile: 'The manufacture of special process equipment follows a strict quality management process. The equipment integrates equipment, piping, valves, structural, electrical, and instrumentation. The equipment integration work is carried out on the basis of design and procurement. We first fabricate and assemble the infrastructure, then install equipment, piping, valves, and electrical instrumentation on the infrastructure.Rigorous inspection and testing to ensure that the requirements of the process ',
            Capabilities:'Equipment manufacturing and integration capabilities',
            Company:'The company\'s equipment manufacturing and equipment integration bases are located in Xuancheng( Anhui), Sanming(Fujian), Wuxi( Jiangsu) and Beibei(Chongqing), which can provide customers with complete sets of equipment manufacturing and equipment services nearby.',


            Anhui:'Manufacturing Workshop in Xuancheng,Anhui',
            AnhuiProfile:' 23,000 square meters, 7 automatic laser welding production lines , an annual output of 400 sets of molten crystallizers',
            Sanming:'Manufacturing workshop in Fujian,Sanming',
            SanmingProfile:'an area of 14,000 square meters, with excellent reactor/tower/heat exchanger manufacturing capacity',
            Jiangsu:'Manufacturing workshop In Wuxi,Jiangsu',
            JiangsuProfile:'an area of 12,000 square meters, which can undertake equipment and skid-like device with different design and manufacturing standards such as GB, ASME, PED, etc',
            Chongqing:'Manufacturing workshop In Beibei，Chongqing ',
            ChongqingProfile:'an area of more than 15,000 square meters, with facilities such as non-destructive testing, pipeline prefabrication, and skid manufacturing',

            Process:'General equipment manufacturing and integration work includes the following (not limited to) :',
            ProcessStep1:'Manufacturing and assembly',
            ProcessStep2:'Factory inspection and testing',
            ProcessStep3:'Paint',
            ProcessStep4:'Package',
            ProcessStep5:'Delivery and transportation according to delivery requirements',

        },
        Delivery: {
            Name: 'Delivery & On-site Service',
            Overview: 'Overview',
            ProfileOne:'Whether you are provided with dedicated process equipment or complete process units, flawless delivery and meticulous on-site service are key to achieving your goals.',
            ProfileTwo:'After the manufacturing of special process equipment or complete sets of process equipment is completed, we will transport it to the customer site as a whole as possible, and if it cannot be transported as a whole, we will split it into several parts after completing the overall performance test, and transport it to the site after proper packaging for secondary assembly and testing;',
            ProfileThree:'The on-site installation system will be assembled in the form of prefabricated parts, minimizing on-site construction time. Our engineers will guide the commissioning and process operation optimization on site, and train the staff for the customer until the equipment can run stably and well.',
            Typical:'Typical delivery and on-site service work includes the following (but not limited to):',
            Training:'training',
            Inspection:'Incoming inspection',
            Installation:'Installation guidance and inspection',
            DCS:'DCS configuration programming and testing',
            Commissioning:'Commissioning and start-up guidance',
            Cooperate:'Cooperate with performance assessment and acceptance',
            Regular:'Regular maintenance reminders and return visits',
            Incident:'Incident response services',
        }
    },
    Information:{
        Name:'News',
        News:'News & Events',
        Crystallizer:'The crystallizer of the vinylene carbonate project in India was successfully delivered',
        Passed:'Successfully passed the on-site inspection of TÜV Rheinland Group',
        Achieved:'Successfully achieved continuous sublimation and purification of ODA',
    },
    AboutUs:{
        Name: 'About Us',
        Id:'Who We Are',
        Profile: 'We are a technology-based enterprise that provides customers with advanced process technology and innovative equipment solutions.Our bisiness covers chemicals, environmental protection, energy, materials and pharmaceuticals.we follow the concept of green chemical industry and adop technologies such as functional integration, continuous flow and process intensification. By promoting the miniaturization of production equipment, the intelligence of production processes and the maximization of resource utilization, we aim to improve production efficiency and product quality, reduce safety accidents, reduce labor intensity, and create lasting competitive advantages for our partners.',
        Organizational:'Organizational structure',

        Culture: 'Corporate Culture',
        Mission: 'Corporate Mission',
        Advanced: 'Develop advanced process technology, innovate production equipment, and develop safe, clean and intelligent chemical production.',
        Vision: 'Corporate Vision',
        Provider1: 'To become an industry-leading service provider of advanced technology and innovative equipment solutions;',
        Provider2: 'To be the preferred partner of our customers;',
        Provider3: 'Become the best platform for employees to achieve their careers.',
        Values: 'Corporate Values',
        Equality: 'Equality and autonomy, trust and responsibility, Co-creation and win-win.',

        Year2021:'2021',
        Year2022:'2022',
        Year2023:'2023',
        Year2024:'2024',
        History: 'Company History',
        Molecular: 'Our molecular distiller has been industrially successfully applied for VC',
        Catalytic: 'Fixed-bed catalytic oxidation was successfully developed for the preparation process technology of epoxy butene',
        Continuous: 'Continuous sublimation&condensation process technology and integrated equipments was successfully developed',
        Realize: 'Realize fixed-bed catalytic oxidation for Synthesis of vinyl sulfate',
        Honors: 'Honors & Patents',
    },
    Footer: {
        Telephone: 'Telephone',
        Phone: 'Mobile Phone',
        Email: 'Email',
        Address: 'Address',
        Welcome: 'You\'re Welcome',
        Introduction: 'Company Introduction',
        Integration:'Equipment manufacturing & integration',
        Technology:  'Unit technology & equipment',
        News: 'News & Events',
        Location: 'International Bio-City Banan District Chongqing City China',
        Slogan: 'Equality, Autonomy, Trust, Responsibility, Co-creation, Mutual Success',
        ProfileOne: 'Developing advanced process technologies and innovative production equipment to build a safe, clean, and intelligent chemical production.',
        ProfileTwo: '',
        Copyright: 'Copyright © 2020-2024:Chongqing MinHeng Technology Co., Ltd',
        PoweredByOne: 'Chongqing ICP Filing No.: 2021011164-1',
        PoweredByTwo: 'Chongqing Public Network Security Filing No.: 50011302222017',
    }
};
