<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner3.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{ menuType == 1 ? $t("Product.Crystallization.TabOne.Title") : menuType == 2 ? $t("Product.Crystallization.TabTwo.Title") : $t("Product.Crystallization.TabThree.Title")}}</div>
        <div class="p-banner-sub-title">{{ menuType == 1 ? $t("Product.Crystallization.TabOne.Title") : menuType == 2 ? $t("Product.Crystallization.TabTwo.Title") : $t("Product.Crystallization.TabThree.Title")}}</div>
      </div>
    </div>
    <div class="page-menu d-flex-center" :class="$i18n.locale === 'en' ? 'dis-center' : ''">
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">{{$t("Product.Crystallization.TabOne.Name")}}</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">{{$t("Product.Crystallization.TabTwo.Name")}}</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">{{$t("Product.Crystallization.TabThree.Name")}}</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">{{$t("Product.Crystallization.TabOne.SubTitle")}}</div>
      <div class="page-text">{{$t("Product.Crystallization.TabOne.SubProfile")}}</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="36">
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-1.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-2.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-3.png" v-image-preview alt=""></div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj1-4.png" v-image-preview alt=""></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Crystallization.TabOne.Application")}}</div>
      <el-row class="flex-warp mb-40 radius-10" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="13" :md="13" :sm="13" :xl="13" :xs="24">
          <div class="preview-image"><img class="box-img" style="height: 100%" src="../../assets/img/product/shjj1-5.png" v-image-preview ></div>
        </el-col>
        <el-col :lg="11" :md="11" :sm="11" :xl="11" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Iodine")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Sublimation")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Purified")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Camphor")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Metal")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Melamine")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Organic")}}</div>
            <div class="dot-list"><div class="dot"></div>{{$t("Product.Crystallization.TabOne.Separation")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Crystallization.TabOne.TechnicalFeatures")}}</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabOne.Chemical")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabOne.Substances")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabOne.Quality")}}</div>
      </div>
    </div>
    <div v-else-if="menuType == 2" class="container">
      <div class="product-item-title">{{$t("Product.Crystallization.TabTwo.Summary")}}</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>{{$t("Product.Crystallization.TabTwo.SubProfile")}}</div>
      <div class="product-item-title">{{$t("Product.Crystallization.TabTwo.Sublimator")}}</div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
          <div class="preview-image"><img class="box-img" style="height: 100%" src="../../assets/img/product/shjj3-1.jpg" v-image-preview ></div>
        </el-col>
        <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
          <div class="dot-box">
            <div class="dot-list f-bold">{{$t("Product.Crystallization.TabTwo.Performance")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Continuously")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Short")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Residue")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Efficiency")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Heating")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Energy")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Flexibility")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Crystallization.TabTwo.Condenser")}}</div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
          <div class="preview-image"><img class="box-img" style="height: 100%" src="../../assets/img/product/shjj3-2.jpg" v-image-preview ></div>
        </el-col>
        <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
          <div class="dot-box">
            <div class="dot-list f-bold">{{$t("Product.Crystallization.TabTwo.Characteristics")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Continuous")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Stably")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Degree")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Crystal")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabTwo.Directed")}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">{{$t("Product.Crystallization.TabThree.Summary")}}</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>{{$t("Product.Crystallization.TabThree.SubProfile")}}</div>
      <div class="product-item-title">{{$t("Product.Crystallization.TabThree.Equipment")}}</div>
      <div class="page-text">{{$t("Product.Crystallization.TabThree.EquipmentProfile")}}</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title">{{$t("Product.Crystallization.TabThree.Sublimation")}}</div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj4-1.png" alt="" v-image-preview ></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="img-item-title"></div>
            <div class="preview-image"><img class="page-img" src="../../assets/img/product/shjj4-2.png" alt="" v-image-preview ></div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Product.Crystallization.TabThree.Advantages")}}</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabThree.Separation")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabThree.Skid")}}</div>
        <div class="page-text"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Product.Crystallization.TabThree.Extent")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // name: "升华结晶",
  data(){
    return{
      menuType:1
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
