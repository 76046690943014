import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入i18n插件
import VueI18n from 'vue-i18n'
// 引入语言包
import en from './lang/en'
import zh from './lang/zh'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'

Vue.use(VueDirectiveImagePreviewer,
    {
        //1. wrapper背景颜色———— 你也可以写成background: '#000'  //支持rgba、rgb和image: 'url(xxx)'
        background: {
            color: '#000'
        },
        zIndex: 100,
        cursor: 'pointer'
    })


// 使用VueI18n插件
Vue.use(VueI18n)
const messages = {
    en,
    zh
}
// 创建 VueI18n 实例
const i18n = new VueI18n({
    locale: localStorage.getItem("langType") ? localStorage.getItem("langType") : 'zh', // 设置默认语言
    messages: messages,
});
// 保存语言值：1.获取之前保存的语言设置，2.动态切换语言

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        if(i18n.locale === 'en'){
            document.title = to.meta.enTitle; // 设置页面标题
        }else{
            document.title = to.meta.title; // 设置页面标题
        }
    }
    window.scrollTo(0, 0);
    next();
});

Vue.prototype.$goPage = function (url) {
    this.$router.push({
        path: url
    })
}

//全局变量
let isPc = true
const userAgentInfo = navigator.userAgent;
const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
for (let i = 0; i < mobileAgents.length; i++) {
    if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
        isPc = false
    }
}
let val = {
    isPc: isPc
}

Vue.prototype.$baseVal = val

var aCount = 0

function animateOnIntersect(el, binding) {
    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !entries[0].target.className.includes('animation')) {
            // 当元素交叉进入视口时
            el.classList.add('animation')
            switch (aCount % 4) {
                case 0:
                    el.classList.add('toRight')
                    break;
                case 1:
                    el.classList.add('toLeft')
                    break;
                case 2:
                    el.classList.add('toTop')
                    break;
                case 3:
                    el.classList.add('toBottom')
                    break;
            }
            aCount += 1
        }
    });

    // 开始观察
    observer.observe(el);
}

Vue.directive('animate-on-intersect', {
    inserted: animateOnIntersect
});

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
