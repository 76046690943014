<template>
  <div>
    <div class="p-banner mb-40">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner7.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{$t("Product.Lithium.Name")}}</div>
        <div class="p-banner-sub-title">{{$t("Product.Lithium.Name")}}</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title">{{$t("Product.Lithium.SubName")}}</div>
      <el-row class="flex-warp five-col" :gutter="25" justify="space-between" type="flex">
        <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
          <div class="product-item download-item" @click="downloadFile('/ldc/02-04-1-氯代碳酸乙烯酯工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/ldctjj-1.png">
            <div class="product-item-name2">{{$t("Product.Lithium.CEC")}}</div>
          </div>
        </el-col>
        <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
          <div class="product-item download-item" @click="downloadFile('/ldc/02-04-2-碳酸亚乙烯酯工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/ldctjj-2.png">
            <div class="product-item-name2">{{$t("Product.Lithium.VC")}}</div>
          </div>
        </el-col>
        <el-col :xl="4" :lg="4" :md="4" :sm="8" :xs="12">
          <div class="product-item download-item" @click="downloadFile('/ldc/02-04-3-氟代碳酸乙烯酯工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/ldctjj-3.png">
            <div class="product-item-name2">{{$t("Product.Lithium.FEC")}}</div>
          </div>
        </el-col>
        <el-col :xl="4" :lg="4" :md="4" :sm="12" :xs="12">
          <div class="product-item download-item" @click="downloadFile('/ldc/02-04-4-亚硫酸乙烯酯工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/ldctjj-4.png">
            <div class="product-item-name2">{{$t("Product.Lithium.ES")}}</div>
          </div>
        </el-col>
        <el-col :xl="4" :lg="4" :md="4" :sm="12" :xs="12">
          <div class="product-item download-item" @click="downloadFile('/ldc/02-04-5-硫酸乙烯酯工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/ldctjj-5.png">
            <div class="product-item-name2">{{$t("Product.Lithium.DTD")}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  // name: "锂电池添加剂",
  methods:{
    downloadFile(url){
      window.open('/downloadFile' + url);
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
