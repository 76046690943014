<template>
  <div>
    <div class="p-banner mb-40">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner1.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{$t("Product.product")}}</div>
        <div class="p-banner-sub-title">{{$t("Product.product")}}</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title" v-animate-on-intersect>{{$t("Product.TypeOne.Name")}}</div>
      <el-row :gutter="50" class="flex-warp" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item" @click="$goPage('/product/page-rrjj')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-1.png"></div>
            <div class="product-item-name">{{$t("Product.TypeOne.CardOne.Name")}}</div>
            <div class="product-item-desc">{{$t("Product.TypeOne.CardOne.Slogan")}}</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item" @click="$goPage('/product/page-fzzl')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-2.png"></div>
            <div class="product-item-name">{{$t("Product.TypeOne.CardTwo.Name")}}</div>
            <div class="product-item-desc">{{$t("Product.TypeOne.CardTwo.Slogan")}}</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item" @click="$goPage('/product/page-shjj')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-3.png"></div>
            <div class="product-item-name">{{$t("Product.TypeOne.CardThree.Name")}}</div>
            <div class="product-item-desc">{{$t("Product.TypeOne.CardThree.Slogan")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title" v-animate-on-intersect>{{$t("Product.TypeTwo.Name")}}</div>
      <el-row :gutter="50" class="flex-warp" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item"  @click="$goPage('/product/page-ldctjj')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-4.png"></div>
            <div class="product-item-name">{{$t("Product.TypeTwo.CardOne.Name")}}</div>
            <div class="product-item-desc">{{$t("Product.TypeTwo.CardOne.Slogan")}}</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item"  @click="$goPage('/product/page-qqsysw')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-5.png"></div>
            <div class="product-item-name">{{$t("Product.TypeTwo.CardTwo.Name")}}</div>
            <div class="product-item-desc">{{$t("Product.TypeTwo.CardTwo.Slogan")}}</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item"  @click="$goPage('/product/page-sfhfczyhly')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-6.png"></div>
            <div class="product-item-name">{{$t("Product.TypeTwo.CardThree.Name")}}</div>
            <div class="product-item-desc">{{$t("Product.TypeTwo.CardThree.Slogan")}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
