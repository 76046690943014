<template>
  <div>
    <div class="p-banner mb-40">
      <img alt="" class="p-banner-bg" src="../assets/img/news/banner.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">  {{$t("Information.Name")}}</div>
        <div class="p-banner-sub-title">{{$t("Information.Name")}}</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title">{{$t("Information.News")}}</div>
      <el-row :gutter="50" class="flex-warp" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :lg="8" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="product-item">
            <div class="product-item-img"><img alt="" src="../assets/img/news/news-1.png"></div>
            <div class="news-item-name">{{$t("Information.Crystallizer")}}</div>
            <div class="news-item-desc">
              <div>2024.05</div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="product-item">
            <div class="product-item-img"><img alt="" src="../assets/img/news/news-2.png"></div>
            <div class="news-item-name">{{$t("Information.Passed")}}</div>
            <div class="news-item-desc">
              <div>2024.03</div>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="product-item">
            <div class="product-item-img"><img alt="" src="../assets/img/news/news-3.png"></div>
            <div class="news-item-name">{{$t("Information.Achieved")}}</div>
            <div class="news-item-desc">
              <div>2024.01</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  // name: "资讯"
}
</script>

<style>
@import "../assets/css/product.css";
</style>
