export default {
    Header: {
        Home: '首页',
        Product: '产品',
        Service: '服务',
        News: '资讯',
        About: '关于我们',
        Title:'重庆敏恒科技有限公司'
    },
    Home: {
        CardOne: {
            Product: '产品',
            Slogan: '性能保证的单元技术与设备',
            More: '了解更多'
        },
        CardTwo: {
            Product: '产品',
            Slogan: '成熟可靠的先进工艺技术',
            More: '了解更多'
        },
        CardThree: {
            Service: '服务',
            Slogan: '从实验室到工业化装置的全流程专业服务',
            More: '了解更多'
        },
    },
    Product: {
        product: '我们的产品',
        TypeOne: {
            Name: '单元技术与设备',
            CardOne: {
                Name: '熔融结晶',
                Slogan: '高纯化学品生产优选方案',
            },
            CardTwo: {
                Name: '分子蒸馏',
                Slogan: '高纯化学品生产优选方案',
            },
            CardThree: {
                Name: '升华结晶',
                Slogan: '特殊的高纯分离解决方案',
            },
        },
        TypeTwo: {
            Name: '工艺技术',
            CardOne: {
                Name: '锂电池添加剂',
                Slogan: '行业领先的成熟可靠技术',
            },
            CardTwo: {
                Name: '氢氰酸衍生物',
                Slogan: '成熟产品的新技术',
            },
            CardThree: {
                Name: '三废资源化技术',
                Slogan: '困难问题的解决新方案',
            },
        },
        Melt: {
            Name: '熔融结晶技术',
            TabOne: {
                Name: '技术介绍',
                Title: '熔融结晶技术',
                SubTitle: '技术原理',
                SubProfile: '熔融结晶是一种根据待分离物质之间凝固点不同而实现物质分离提纯的方法，是一种低能耗的有机物清洁分离工艺，可高效分离沸点接近的烃类、同分异构体和热敏材料等特殊物系，超纯物质的制备是熔融结晶的特有优势。熔融结晶通过降温结晶、升温发汗和加热熔融三个过程的操作得到高纯产品。',
                //典型应用
                Use: '典型应用',
                VCPur: '将精馏到95%纯度的碳酸亚乙烯酯（VC）提纯到99.99%',
                FECPur: '将99%的氟代碳酸乙烯酯（FEC）提纯到99.95%',
                Polymer: '将丙烯酸中阻聚剂含量从200ppm降低至20ppm',
                Separation: '分离对甲酚/间甲酚的混合物',
                Benzoic: '将工业级苯甲酸提纯为食品级苯甲酸',
                Industrial: '将工业级苯甲酸卞酯提纯到香精香料级',
                Chloroacetic: '高纯氯乙酸制备',
                Phosphoric: '生产电子级磷酸',
                Naphthalene: '提纯萘油 苯酚 碳酸乙烯酯',
                Acetonitrile: '生产色谱级乙腈',
                //技术特点
                Feature: '技术特点',
                Energy: '节能：',
                EnergyDescribe: '熔融热仅是汽化热的1/7~1/3，熔融结晶综合能耗仅为精馏能耗的10%~30%',
                Temperature: '低温操作：',
                TemperatureDescribe: '常压、低温操作，非常适用于热敏性物质、稀溶液、高沸点物质的分离提纯',
                Solvent: '不引入溶剂：',
                SolventDescribe: '熔融结晶不需外加溶剂，即避免了溶剂对产品的污染，又减少了溶剂回收过程',
                Materials: '适用于特种物系：',
                MaterialsDescribe: '适用于沸点相近、同分异构体、手性物质等用常规的精馏极难分离体系',
                Purity: '产品纯度高：',
                PurityDescribe: '熔融结晶产品纯度一般可达到99.9%以上，最高可到99.999%'
            },
            TabTwo: {
                Name: '核心设备',
                Title: '静态板式熔融结晶',
                Summary: '概述',
                SubProfile: '板式熔融结晶器为静态层熔融结晶单元的核心设备，规格从1L到30m3，无论应用规模大小，敏恒科技都能满足要求。我们将根据您的物料组成和分离要求进行设计，让您的工艺与最佳的设备性能相匹配。',
                Structure: '结构',
                StructureProfile: '板式熔融结晶器由外箱体、结晶板和支撑板组成，无传动部件，静态间歇批次操作，免维护、易操作，可胜任各种化学品的高纯度生产要求。结构组成：',
                OuterContainer: '外籍',
                Crystals: '结晶板',
                Crystallization: '结晶板组',
                Assembly: '组装成品',
                Features: '设计特征',
                Strength: '结晶板为高强度薄板，设备重量轻，安装所需基础负荷小',
                Design: '独特的流道设计，确保结晶板温度场均匀，结晶板不同位置温差＜0.1℃，有效保证分离效率',
                Support: '结晶板之间设置支撑板，强化传热的同时使支撑晶层发汗时不塌陷，提升发汗效率',
                Shape: '规整的长方体外形，空间利用效率高，安装、检修和维护极其方便',
                BoxDesign: '匹配结晶要求的外箱设计，无结晶死区，放料方便，出料彻底',
            },
            TabThree: {
                Name: '成套装置',
                Title: '熔融结晶成套设备',
                Summary: '概述',
                SubProfile: '敏恒科技致力于提供最高效和最可靠的成套设备，以保证您的结晶器得到最佳的使用性能。为了获得最佳的使用性能，从设计与工艺相匹配的结晶器、到配套的公用工程和氮气保护系统，我们以严谨、科学的原则解决所有设计和制造方面的问题，实现系统的完整集成。',
                Equipment: '成套设备组成',
                EquipmentProfile: '熔融结晶成套设备由结晶器、储运系统、公用工程、氮气保护系统、管道阀门、电气仪表、控制系统、梯子平台、保温伴热等二级子单元组成，根据规模大小分可为撬装设备或现场安装系统。撬装设备将在生产车间完成组装和测试，然后分拆运输到客户现场进行二次组装并调试；现场安装系统将在客户现场完成组装和调试。',
                CrystallizationOne: '一级结晶成套撬装设备',
                CrystallizationThree: '三级结晶现场安装系统',
                Advantages: '成套设备的优点',
                Core: '尽管结晶器的设计是核心，但辅助系统对于设备的高效稳定运行也非常关键，成套设备最大程度利用了敏恒在熔融结晶设备辅助系统方面的专业知识和丰富经验，可以确保客户设备投入运营即获得最佳使用性能',
                Profitable: '撬装设备或现场安装系统服务将最大限度地的节省客户投入项目的人力资源和精力，客户可将其相关资源用在最能产生效益的地方',
                Extent: '最大程度避免各供应商单⼀供货模式造成的设计与硬件匹配连贯性不⾜、多界⾯交接复杂、交付不确定、项⽬建设⼯期延长等问题',
            }
        },
        Distillation: {
            Name: "分子蒸馏技术",
            TabOne: {
                Name: '技术介绍',
                Title: '分子蒸馏技术',
                //技术原理
                SubTitle: '技术原理',
                MolecularDistillationProcess: "分子蒸馏是一种特殊的液--液分离技术，它在高真空下操作，能使液体在远低于其沸点的温度下将其分离。它是利用分子运动平均自由程的原理，将液体混合物稍稍加热，使液体分子从液面逸出，轻分子落在冷凝面而重分子又返回，如此实现液体的分离。分子蒸馏过程通过如下四个步骤完成：",
                Liquid: '① 分子从液相向蒸发表面扩散',
                Evaporation: '② 分子在液相表面上的自由蒸发',
                Fly: '③ 分子从蒸发表面向冷凝面飞射',
                Moleccondenese: '④ 分子在冷凝面上冷凝',
                //典型应用
                Application: '典型应用',
                Volatile: '脱除树脂和聚合物中的挥发性组份',
                Fractions: '脱除天然蜡中的低沸点组分',
                Purification: '浓缩和提纯乳酸、甘油、芳香胺、脂肪酸酰胺聚苯化合物',
                Pharmaceutical: '分离提纯二聚脂肪酸、杀虫剂、除菌剂、除草剂、高碳醇、石蜡、医药中间体',
                Sulfoxide: '回收环丁砜、二甲基亚砜、二甲基甲酰胺和二甲基乙酰胺',
                Residues: '化学品除臭和去除农药残留',
                Refined: '精制丙烯酸及丙烯酸酯、碳酸酯',
                Oil: '纯化玫瑰油、山仓子油、桉叶油、香茅油、橙油、紫罗兰酮等芳香物质',
                //技术特点
                TechnicalFeatures: "技术特点",
                Operation: '操作真空度高：',
                OperationDescribe: '由于分子蒸馏的冷热面间的间距小于轻分子的平均自由程，蒸发面的实际操作真空度比传统真空蒸馏的真空度高，操作压可达到0.1～1Pa数量级',
                Temperature: '操作温度低：',
                TemperatureDescribe: '分子蒸馏依靠分子运动平均自由程的差别实现分离，并不需要到达物料的常压沸点',
                Time: '分离过程中物料受热时间短：',
                TimeDescribe: '分子蒸馏在蒸发过程中，物料被强制形成很薄的液膜，并被定向推动，使得液体在分离器中停留时间很短，特别是轻分子，一经逸出就马上冷凝，受热时间更短，物料的热损伤极小',
            },
            TabTwo: {
                Name: '核心设备',
                Title: '分子蒸馏器',
                Summary: '概述',
                SubProfile: '将产品从高粘度的反应聚合副产物中分离或将高沸点、高粘度、热敏物料蒸馏提纯，常规的蒸馏设备难以得到理想的结果，特殊设计的分子蒸馏器是解决此类问题的利器。 分子蒸馏器将加热和冷凝组合在一起，并通过成膜刮板将进入加热面的物料连续成膜分散，让蒸发组份快速从加热面逸出，在冷凝面被冷凝收集。分子蒸馏的这一特点让其可以有效处理粘性材料和适用于有污垢的应用。',
                Structure: '结构',
                StructureProfile: '分子蒸馏器由带加热夹套的外筒体、内冷器、刮板刮膜器、布液和密封结构、驱动电机等组成。高精密的传动与机械密封设计、整体加工成型的蒸发筒体、独特的液体分布器和防溅设备是保证设备长期平稳运行的关键。',
                Cylinder: '光洁的外筒体',
                Board: '防贱板',
                Interior: '内冷器',
                Assembly: '组装成品',

                Features: '设计特征',
                Design: '根据具体物料体系进行设备设计，真正做到工艺和设备的最佳匹配',
                Polymer: '对副产聚合物含量高、黏度大的体系，可以设置辅助出料机构辅助出料',
                Life: '独特的成膜结构设计，低转速即可实现良好成膜，极大的延长刮板刀片、机械密封和驱动轴承的使用寿命',
            },
            TabThree: {
                Name: '成套装置',
                Title: '分子蒸馏成套设备',
                Summary: '概述',
                SubProfile: '一台设计制造优良的分子蒸馏器，如果没有适配的辅助系统配合，也难以发挥出应有的性能，敏恒科技利用其专业技能和丰富经验，可为您提供从公用工程配套到真空系统集成的成套设备，让您无忧获取性能最佳的分子蒸馏系统。',
                Equipment: '成套设备组成',
                EquipmentProfile: '分子蒸馏成套设备由分子蒸馏器、轻重相接收罐、冷阱、真空机组等设备和管道阀门、电气仪表、梯子平台、保温伴热等二级子单元组成，根据规模大小分可为撬装设备或现场安装系统。撬装设备将在生产车间完成组装和测试，运输到客户现场，接上公用工程和进出物料管线即可投入运行；现场安装系统将在客户现场完成组装和调试。',
                Mounted: '分子蒸馏成套撬装设备',
                System: '分子蒸馏现场安装系统',

                Advantages: '成套设备的优点',
                Molecular: '分子蒸馏成套设备的设计和制造涉及很多方面，包括工艺需要的预热、预脱气、单级蒸发或是多级蒸发以及真空、公用工程、产品和废料处理等。敏恒的量身定制将确保整个系统实现最佳。其中的细节包括：',
                Stable: '高真空条件下的稳定上料和流量控制',
                Determine: '根据物料特性、产能和分离要求的蒸发级数确定、各级间蒸发速率分配',
                Adopt: '采用适当的预处理工艺，降低对分子蒸馏加热和冷却的面积要求，节省系统投资',
                Characteristics: '根据物料特性，匹配正确的真空系统和尾气处理设备，实现长周期稳定运行',
            },
        },
        Crystallization: {
            Name: '升华结晶技术',
            TabOne: {
                Name: '技术介绍',
                Title: '升华结晶技术',
                SubTitle: '技术原理',
                SubProfile: '升华结晶是指固态物质不经过液态直接转变为气态及气态物质不经过液态直接变为固态的过程，其包含固体升华和气体凝华两个过程。当待分离固态物系中各组分在气相中分压差存在显著差别，且各组分对热相对稳定，加热待分离固态物系，易升华组分变为气态从固态中挥发出来，冷却挥发出来的气体，使其凝华，即可得到易升华组分的提纯晶体。',
                //典型应用
                Application: '典型应用',
                Iodine: '从含碘废液中回收单质碘',
                Sublimation: '将粗硫升华结晶制备用于轮胎、胶管和胶板等升华硫',
                Purified: '将粗萘经过升华提纯得到98.5%以上纯度的精萘',
                Camphor: '升华提纯粗樟脑至99.5%以上纯度',
                Metal: '精制提纯金属卤化物三氯化铁（FeCl3）、三氯化铝（AlCl3）、茂金属等产品',
                Melamine: '从三聚氯氰、苯酐合成气体中连续凝华结晶分离产品',
                Organic: '精制提纯有机光电材料BCP、Alq3、Bpy-OXD、B5T、BP2T等产品',
                Separation: '分离回收或精制提纯水杨酸、对苯醌、苯甲酸、对苯二甲酸等产品',

                //技术特点
                TechnicalFeatures: "技术特点",
                Chemical: '不使用化学溶剂，不产生废液，符合绿色化工的要求',
                Substances: '利用固体物质的升华特性进行分离纯化可得到高纯物质',
                Quality: '可获得高质量可解析单晶'
            },
            TabTwo: {
                Name: '核心设备',
                Title: '升华结晶专用工艺设备',
                Summary: '概述',
                SubProfile: '升华结晶主要有三种工艺，分别为常压升华、真空升华和载气升华。能常压升华的物质不多，大部分物质只能在真空或载气条件下升华，真空升华最为节能，因此，我们开发了适宜真空连续升华的升华器和凝华器，可实现真空条件下的连续升华提纯。',

                Sublimator: '升华器',
                Performance: '性能特点',
                Continuously: '可连续地、全封闭地生产',
                Short: '物料停留时间短',
                Residue: '最终残渣中产品残留少',
                Efficiency: '卓越的混合效率',
                Heating: '加热表面可自清洁、',
                Energy: '能源消耗低',
                Flexibility: '通过可更换的转子元件实现其灵活性',

                Condenser: '凝华器',
                Characteristics: '性能特点',
                Continuous: '连续凝华、连续采出',
                Stably: '可在-30～250℃,10～1000Pa操作条件下长期稳定运行',
                Degree: '自动化程度高，一键启停',
                Crystal: '产品晶型可控(片状、粉状)',
                Directed: '定向凝华，无结晶堵塞风险',
            },
            TabThree: {
                Name: '成套装置',
                Title: '升华结晶成套设备',
                Summary: '概述',
                SubProfile: '升华结晶作为一种无溶剂结晶方法，在高纯产品的生产、物质新晶型筛选、高质量单晶制备等方面具有极大优势。升华结晶包含了升华和凝华两个过程，将升华器和凝华器进行组合，配套辅助设备、电气仪表和管道阀门，从而得到满足质量、收率、能耗等方面广泛要求的成套设备。',

                Equipment: '成套设备组成',
                EquipmentProfile: '升华结晶成套设备由升华器、凝华器、进料系统、出料系统、公用工程、氮气保护系统、管道阀门、电气仪表、控制系统、梯子平台、保温伴热等二级子单元组成，根据规模大小分可为撬装设备或现场安装系统。撬装设备将在生产车间完成组装和测试，然后分拆运输到客户现场进行二次组装并调试；现场安装系统将在客户现场完成组装和调试。',
                Sublimation: '升华结晶成套撬装设备',

                Advantages: '成套设备的优点',
                Separation: '无论是合成物料的凝华结晶分离，还是固体物料的升华结晶提纯，匹配的辅助系统是装置长期稳定运行的关键，成套设备可以让你一步到位获取工艺性能保证的生产装置',
                Skid: '撬装设备或现场安装系统服务将最大限度地的节省您投入项目的人力资源和精力，您可将其相关资源用在最能产生效益的地方',
                Extent: '最大程度避免各供应商单⼀供货模式造成的设计与硬件匹配连贯性不⾜、多界⾯交接复杂、交付不确定、项⽬建设⼯期延长等问题',
            },
        },
        Lithium: {
            Name: '锂电池添加剂',
            SubName: '在锂电池添加剂系列产品中，我们提供下列产品的工艺技术包服务',
            CEC: '氯代碳酸乙烯酯（CEC）',
            VC: '碳酸亚乙烯酯（VC）',
            FEC: '氟代碳酸乙烯酯（FEC）',
            ES: '亚硫酸乙烯酯（ES）',
            DTD: '硫酸乙烯酯（DTD）',
        },
        Hydrocyanic: {
            Name: '氢氰酸衍生物系列产品',
            SubName: '在氢氰酸衍生物产品中，我们提供下列产品的工艺技术包服务',
            A3: '(S)-4-氯-3-羟基丁腈（A3）',
            A4: '(S)-4-氯-3-羟基丁酸乙酯（A4）',
            A5: '(R)-4-氰基-3-羟基丁酸乙酯（A5）',
        },
        Waste: {
            Name: '三废或副产资源化利用系列产品',
            SubName: '在三废或副产资源化利用系列产品中，我们提供下列产品的工艺技术包服务',
            Calcium: '电石渣制备高纯碳酸钙',
        },
    },
    Service: {
        Name: '我们的服务',
        Process: {
            Name: '工艺开发与测试',
            Overview: '概述',
            Profile: '对任何一个具体的分离体系和分离任务，通常有多种方法可以选择，最佳方案的获取，需要借助高质量的开发研究和多次的测试。',
            Development: '开发研究',
            DevelopmentProfile: '开发研究在专业的知识、丰富的经验和多年积累数据的基础上进行，借助先进的流程模拟工具，我们可以对各种可能方案的投资、能耗、产品质量和运行成本等进行对比分析，从而获取理想的解决方案。 在开发研究中，通过公开文献和已有数据库等不能获取的数据，我们将通过研发中心的检测和实验来获取。',
            Research: '开发结果',
            ResearchProfile: '开发结果将形成一份初步技术方案，为你提供你项目下一步决策所需要的所有信息，包括所采用的分离技术、预期的产品纯度、能耗和投资等详细信息。技术方案通常包含下列信息，我们将在开发完成后提供给您。',
            Design: '设计基础和假设条件',
            Process: '工艺描述',
            Diagram: '工艺流程简图',
            Data: '主要工艺数据',
            Energy: '主要能量消耗',
            Equipment: '关键设备一览表',
            Recommend: '建议',

            Capabilities: '开发能力',
            CapabilitiesProfile: '我们的开发实验室位于重庆国际生物城，实验室根据现代化工过程开发所需软硬件设施进行建设，配备了反应动力学、反应热力学等反应开发设备和分子蒸馏、精馏、萃取、熔融结晶、升华结晶等分离测试设备，初步具备从实验室到第一套工业装置的全过程开发能力。',
            Laboratory: '工艺开发实验室',
            Analytical: '分析检测室',
            Separation: '分离测试实验装置',
            FixedBed: '固定床催化反应实验装置',
            PilotProfile: '我们的中试及产业化示范基地位于贵州丹寨工业园区，基地配备了较为完备的公用工程和污水处理系统，可方便快捷实施中试试验和产业化示范运营。',
            Pilot: '中试车间',
            Nitrogen: '中试车间制氮系统',
        },
        Design: {
            Name: '工程设计',
            Overview: '概述',
            Profile: '无论是为您提供关键专用工艺设备，还是提供成套工艺装置，我们可以根据您的要求，向您提供基础工程设计包和详细工程设计文件。',
            Basic:'基础工程设计',
            BasicProfile:'基础工程设计包将为一个新装置的建设提供必要的工艺技术信息，基础工程设计包的内容和深度将根据客户的具体要求确定。我们的基础工程设计包内容通常包括：',
            Description:'工艺描述',
            Diagram:'工艺流程图 (PFD)',
            Balance:'物料和能量平衡',
            Utilities:'公用工程一览表',
            Plumbing:'管道仪表流程图(P&ID)',
            Facilities:'设备数据表',
            Instrument:'仪表数据表',
            Control:'工艺控制描述',
            Preliminary:'初步的设备布置图',
            Operating:'初步的操作手册',
            Detailed:'详细工程设计',
            DetailedProfile:'当我们为您提供成套工艺装置时，我们将同步向您提供详细工程设计文件，详细工程设计文件通常包括以下内容的全部或几项：',

            Specialization:'（1）工艺专业',
            Instrumentation:'管道及仪表流程图（PID）',
            Utility:'公用工程管道及仪表流程图（UID）',
            Mass:'物料平衡图（PFD）',
            UtilityBalance :'公用工程平衡图（UFD）',
            PID:'管道仪表流程图(P&ID)',
            ProcessDescription:'工艺概述',
            FacilitiesList:'设备清单（材质、型号、规格、重量、尺寸）',
            Pipelines:'管道一览表（含压力管道标识）',
            FacilitiesData:'设备数据单',
            Equipment:'设备荷载表',
            Relationship:'联锁关系表',

            EquipmentFacilities:'（2）设备专业',
            Drawings:'设备外形图（包括总装图、零部件图、预焊件）',
            Strength:'强度计算书',
            DiagramDevices:'隔热层支撑件设计条件图',
            EquipmentData:'动设备数据单',
            Complete:'成套设备数据单',

            Electrical:'（3）电气专业',
            EquipmentList:'用电设备表',
            SchematicDiagram:'电气原理图',
            WiringDiagram:'电气设备接线图',
            Location:'设备接地点位置图',
            Cable:'电缆表及布线图',
            Lighting:'装置照明设计',
            Comprehensive:'综合材料表',
            Information:'变频设备相关信息（如电机转速、减速机减速）',

            InstrFacilities:'（4）仪表专业',
            InstrumentsList:'仪表清单（包括仪表位号、型号、测量范围）',
            Automatic:'自动控制方案说明',
            InterLocking:'自动联锁工艺说明',
            DCS:'DCS I/O 表',
            Layout:'仪表配管配线平面布置图',
            Loop:'仪表回路图',
            Power:'仪表供电系统图',
            Grounding:'仪表接地系统图',
            Toxic:'可燃、有毒气体报警器分布图',
            Interlocks:'报警和联锁一览表',
            Material:'综合材料表',


            PlumbingLayout:'（5）管道及布置专业',
            MaterialList:'设备基础图',
            Platform:'操作检修平台及斜爬梯的设计条件图',
            Drawing:'设备布置图',
            Building:'土建条件图',
            Integrative:'综合材料表',
            DataSheets:'阀门规格书',
            Hanger:'管道支吊架表',
            Pipe:'管道支吊架图',
            Tracing:'管道配管图及单线图',
            Heat:'管道伴热表',
            HeatTrace:'伴热管道系统图',
            InsulationSheet:'保温一览表',
            Safety:'安全淋浴、洗眼器分布图',
        },
        Integration: {
            Name: '设备制造与集成',
            Overview: '概述',
            Profile: '专用工艺设备的制造遵循严格的质量管理流程。成套设备集合了设备、管道、阀门、结构、电气和仪表。设备集成工作在设计、采购的基础上进行，首先进行基础结构的制造与装配，其后在基础结构上安装设备、管道、阀门和电气仪表等，制造完成之后进行检验和测试，以确保满足工艺的要求。',
            Capabilities:'设备制造与集成能力',
            Company:'公司设备制造及装置集成基地分布于安徽宣城、福建三明、江苏无锡和重庆北碚，可就近为客户提供设备制造和装置成套服务。',


            Anhui:'安徽宣城基地',
            AnhuiProfile:'厂房面积二万三千平方米，拥有7条全自动激光焊接生产线，年产熔融结晶器400台套',
            Sanming:'福建三明基地',
            SanmingProfile:'厂房面积一万四千平方米，具备优良的反应器/塔/换热器制造能力',
            Jiangsu:'江苏无锡基地',
            JiangsuProfile:'厂房面积一万两千平方米，可承接GB、ASME、PED等不同设计、制造标准的设备、撬块',
            Chongqing:'重庆北碚基地',
            ChongqingProfile:'厂房面积一万五千余平方米，拥有无损检测、管道预制、撬块制造等设施和队伍',

            Process:'一般的设备制造与集成工作包括以下几项（不限于）：',
            ProcessStep1:'制造及组装',
            ProcessStep2:'工厂检验和测试',
            ProcessStep3:'涂漆',
            ProcessStep4:'包装',
            ProcessStep5:'根据交货要求的发货及运输',

        },
        Delivery: {
            Name: '交付与现场服务',
            Overview: '概述',
            ProfileOne:'无论是为您提供专用工艺设备，还是提供成套工艺装置，完美的交付和周详而细致的现场服务是实现目标的关键。',
            ProfileTwo:'专用工艺设备或成套工艺装置制造完成后，我们将尽可能整体运输至客户现场，不能整体运输的，我们将会在完成整体性能测试后拆分为几个部分，妥善包装后运输到现场再进行二次组装和测试；现场安装系统将会',
            ProfileThree:'以预制件形式进行组装，尽可能减少现场施工时间。一切就绪后，我们的调试工程师现场指导调试和过程操作优化，为客户进行员工培训，直至设备能够稳定良好的运行。',
            Typical:'通常的交付及现场服务工作包括以下几项（不限于）：',
            Training:'培训',
            Inspection:'到进货验',
            Installation:'安装指导和检查',
            DCS:'DCS组态编程及测试',
            Commissioning:'调试及开车指导',
            Cooperate:'配合性能考核及验收',
            Regular:'定期的维护提醒和回访',
            Incident:'事故响应服务',

        },
    },
    Information:{
        Name:'资讯',
        News:'新闻与活动',
        Crystallizer:'印度碳酸亚乙烯酯项目结晶器顺利交付',
        Passed:'顺利通过德国莱茵TÜV集团现场检测',
        Achieved:'成功实现 ODA连续升华提纯',
    },
    AboutUs:{
        Name: '关于我们',
        Id:'我们是谁',
        Profile: '重庆敏恒科技有限公司是一家为客户提供先进工艺技术和创新设备解决方案服务的科技型企业。服务领域涵盖化工、环保、能源、材料和制药等。公司秉承绿色化工理念，采用功能整合、连续流和过程强化等技术，推动生产设备微型化、生产过程智能化及资源利用最大化，旨在提升生产效率和产品质量，减少安全事故、降低劳动强度，为合作伙伴创造持久的竞争优势。',
        Organizational:'组织结构',

        Culture: '企业文化',
        Mission: '企业使命',
        Advanced: '开发先进工艺技术，创新生产设备，构建安全、清洁和智能的化工生产。',
        Vision: '企业愿景',
        Provider1: '成为行业领先的先进技术和创新设备解决方案服务供应商；',
        Provider2: '成为客户的首选合作伙伴；',
        Provider3: '成为员工成就事业的最佳平台。',
        Values: '企业价值观',
        Equality: '平等自主、信任担当、共创共赢。',

        Year2021:'2021年',
        Year2022:'2022年',
        Year2023:'2023年',
        Year2024:'2024年',
        History: '创新一览',
        Molecular: '为碳酸亚乙烯酯设计的专用分子蒸馏器成功实现工业化应用',
        Catalytic: '固定床催化氧化制备环氧丁烯工艺技术开发成功',
        Continuous: '完成ODA连续升华凝华工艺技术和成套装置开发',
        Realize: '实现固定床催化氧化合成硫酸乙烯酯',
        Honors: '荣誉与专利',
    },
    Footer: {
        Telephone: '电话',
        Phone: '手机',
        Email: '邮件',
        Address: '地址',
        Welcome: '期待您的联系！',
        Introduction: '公司介绍',
        News: '资讯与动态',
        Integration:'设备制造与集成',
        Technology:  '单元技术与设备',
        Location: '重庆市巴南区国际生物城',
        Slogan: '平等自主·信任担当·共创共赢',
        ProfileOne: '开发先进工艺技术，创新生产设备，',
        ProfileTwo: '构建安全、清洁和智能的化工生产。',
        Copyright: 'Copyright © 2020-2024 重庆敏恒科技有限公司',
        PoweredByOne: '渝ICP备2021011164号-1',
        PoweredByTwo: '渝公网安备 50011302222017',
    }
}
