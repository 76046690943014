<template>
  <div class="home" @touchstart="stopAutoplay" @touchend="startAutoplay"  @mouseenter="stopAutoplay" @mouseleave="startAutoplay">
    <swiper ref="banner" :options="swiperOption" class="banner"  >
      <swiper-slide>
        <div class="banner-item">
          <img alt="" src="../assets/img/home/banner3.png">
          <div class="banner-box container">
            <div class="banner-text">
              <div class="banner-text-type" v-animate-on-intersect> {{ $t("Home.CardOne.Product") }}</div>
              <div class="banner-text-name" v-animate-on-intersect> {{ $t("Home.CardOne.Slogan") }}</div>
              <div class="banner-text-btn" @click="$goPage('/product')">{{$t("Home.CardOne.More")}} <i class="iconfont icon-right"></i></div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="banner-item">
          <img alt="" src="../assets/img/home/banner1.png">
          <div class="banner-box container">
            <div class="banner-text">
              <div class="banner-text-type" v-animate-on-intersect>{{$t("Home.CardTwo.Product")}}</div>
              <div class="banner-text-name" v-animate-on-intersect>{{$t("Home.CardTwo.Slogan")}}</div>
              <div class="banner-text-btn" @click="$goPage('/product')">{{ $t("Home.CardTwo.More") }} <i class="iconfont icon-right"></i></div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="banner-item">
          <img alt="" src="../assets/img/home/banner2.png">
          <div class="banner-box container">
            <div class="banner-text">
              <div class="banner-text-type" v-animate-on-intersect>{{ $t("Home.CardThree.Service") }}</div>
              <div class="banner-text-name" v-animate-on-intersect><div style="font-size: 38px">{{$t('Home.CardThree.Slogan')}}</div></div>
              <div class="banner-text-btn" @click="$goPage('/service')">{{$t('Home.CardThree.More')}} <i class="iconfont icon-right"></i></div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
<!--    <div v-if="$baseVal.isPc" class="prev el-icon-arrow-left" ></div>-->
<!--    <div v-if="$baseVal.isPc" class="next el-icon-arrow-right" ></div>-->
    <div class="pagination"></div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
import 'swiper/swiper-bundle.css';
export default {
  name: 'Home',
  data(){
    return{
      swiperOption:{
        autoplay:{
          delay: 3000,
        },
        // navigation:{
        //   nextEl:'.next',
        //   prevEl:'.prev'
        // },
        pagination:{
          el:'.pagination',
          clickable :true,
        }
      }
    }
  },
  methods:{
    stopAutoplay() {
      this.$refs.banner.swiper.autoplay.stop();
    },
    startAutoplay() {
      this.$refs.banner.swiper.autoplay.start();
    }
  }
}
</script>

<style>
@import "../assets/css/home.css";
@import "../assets/css/screen.css";
</style>
