<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/service/banner.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">
          {{ menuType == 1 ? $t("Service.Process.Name") : menuType == 2 ? $t("Service.Design.Name") : menuType == 3 ? $t("Service.Integration.Name") : $t("Service.Delivery.Name") }}
        </div>
        <div class="p-banner-sub-title">
          {{ menuType == 1 ? $t("Service.Process.Name") : menuType == 2 ? $t("Service.Design.Name") : menuType == 3 ? $t("Service.Integration.Name") : $t("Service.Delivery.Name") }}
        </div>
      </div>
    </div>
    <div class="page-menu service-page-menu page-menu-xs d-flex-center">
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">{{$t("Service.Process.Name")}}</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">{{$t("Service.Design.Name")}}</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">{{$t("Service.Integration.Name")}}</div>
      <div :class="menuType == 4 ? 'on' : ''" @click="menuType = 4">{{$t("Service.Delivery.Name")}}</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">{{$t("Service.Process.Overview")}}</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>
        {{$t("Service.Process.Profile")}}
      </div>
      <div class="product-item-title">{{$t("Service.Process.Development")}}</div>
      <div class="page-text mb-15">
        {{$t("Service.Process.DevelopmentProfile")}}
      </div>
      <div class="preview-image mb-40"><img class="page-img " v-image-preview v-animate-on-intersect
                                            src="../../assets/img/service/page1-7.jpg" alt=""></div>
      <div class="product-item-title">{{$t("Service.Process.Research")}}</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text">
          {{$t("Service.Process.ResearchProfile")}}
        </div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png">{{$t("Service.Process.Design")}}</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png">{{$t("Service.Process.Process")}}</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png">{{$t("Service.Process.Diagram")}}</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png">{{$t("Service.Process.Data")}}</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png">{{$t("Service.Process.Energy")}}</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png">{{$t("Service.Process.Equipment")}}</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png">{{$t("Service.Process.Recommend")}}</div>
      </div>
      <div class="product-item-title">{{$t("Service.Process.Capabilities")}}</div>
      <div class="page-text">
        {{$t("Service.Process.CapabilitiesProfile")}}
      </div>
      <el-row class="flex-warp" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-1.png"
                                            alt=""></div>
            <div class="img-item-btm-title">{{$t("Service.Process.Laboratory")}}</div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-2.png"
                                            alt=""></div>
            <div class="img-item-btm-title">{{$t("Service.Process.Analytical")}}</div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-3.png"
                                            alt=""></div>
            <div class="img-item-btm-title">{{$t("Service.Process.Separation")}}</div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-4.png"
                                            alt=""></div>
            <div class="img-item-btm-title">{{$t("Service.Process.FixedBed")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text">
        {{$t("Service.Process.PilotProfile")}}
      </div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-5.png"
                                            alt=""></div>
            <div class="img-item-btm-title">{{$t("Service.Process.Pilot")}}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-6.png"
                                            alt=""></div>
            <div class="img-item-btm-title">{{$t("Service.Process.Nitrogen")}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 2" class="container page-gcsj">
      <div class="product-item-title">{{$t("Service.Design.Overview")}}</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>
        {{$t("Service.Design.Profile")}}
      </div>
      <div class="product-item-title">{{$t("Service.Design.Basic")}}</div>
      <div class="page-text mb-15">
        {{$t("Service.Design.BasicProfile")}}
      </div>
      <el-row class="flex-warp mb-40 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%"
                                          src="../../assets/img/service/page2-1.png" v-image-preview></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Description")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Diagram")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Balance")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Utilities")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Plumbing")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Facilities")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Instrument")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Control")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Preliminary")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Operating")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Service.Design.Detailed")}}</div>
      <div class="page-text mb-15">
        {{$t("Service.Design.DetailedProfile")}}
      </div>
      <div class="page-text mb-15">  {{$t("Service.Design.Specialization")}}</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                          src="../../assets/img/service/page2-2.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Instrumentation")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Utility")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Mass")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.UtilityBalance")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.PID")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.ProcessDescription")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.FacilitiesList")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Pipelines")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.FacilitiesData")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Equipment")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Relationship")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">{{$t("Service.Design.EquipmentFacilities")}}</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                          src="../../assets/img/service/page2-6.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Drawings")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Strength")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.DiagramDevices")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.EquipmentData")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Complete")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">{{$t("Service.Design.Electrical")}}</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                          src="../../assets/img/service/page2-3.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.EquipmentList")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.SchematicDiagram")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.WiringDiagram")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Location")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Cable")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Lighting")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Comprehensive")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Information")}}
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">{{$t("Service.Design.InstrFacilities")}}</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                          src="../../assets/img/service/page2-4.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.InstrumentsList")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Automatic")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.InterLocking")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.DCS")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Layout")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Loop")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Power")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Grounding")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Toxic")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Interlocks")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Material")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">{{$t("Service.Design.PlumbingLayout")}}</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                          src="../../assets/img/service/page2-5.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.MaterialList")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Platform")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Drawing")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Building")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Integrative")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.DataSheets")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Hanger")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Pipe")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Tracing")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Heat")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.HeatTrace")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.InsulationSheet")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Design.Safety")}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">{{$t("Service.Integration.Overview")}}</div>
      <div class="page-text mb-15">
        {{$t("Service.Integration.Profile")}}
      </div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
          <div class="preview-image"><img class="box-img" v-image-preview style="height: 100%"
                                          src="../../assets/img/service/page3-1.png"></div>
        </el-col>
        <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
          <div class="dot-box">
            <div class="dot-list f-bold">{{$t("Service.Integration.Process")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Integration.ProcessStep1")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Integration.ProcessStep2")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Integration.ProcessStep3")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Integration.ProcessStep4")}}</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Integration.ProcessStep5")}}</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">{{$t("Service.Integration.Capabilities")}}</div>
      <div class="page-text">
        {{$t("Service.Integration.Company")}}
      </div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50" v-animate-on-intersect>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
            <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                            src="../../assets/img/service/page3-2.png"></div>
            <div class="name" :class="$i18n.locale === 'en' ? 'en-name' : ''">{{$t("Service.Integration.Anhui")}}</div>
            <div class="desc" :class="$i18n.locale === 'en' ? 'en-desc' : ''">{{$t("Service.Integration.AnhuiProfile")}}</div>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
            <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                            src="../../assets/img/service/page3-3.png"></div>
            <div class="name" :class="$i18n.locale === 'en' ? 'en-name' : ''">{{$t("Service.Integration.Sanming")}}</div>
            <div class="desc" :class="$i18n.locale === 'en' ? 'en-desc' : ''">{{$t("Service.Integration.SanmingProfile")}}</div>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
            <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                            src="../../assets/img/service/page3-4.png"></div>
            <div class="name" :class="$i18n.locale === 'en' ? 'en-name' : ''">{{$t("Service.Integration.Jiangsu")}}</div>
            <div class="desc" :class="$i18n.locale === 'en' ? 'en-desc' : ''">{{$t("Service.Integration.JiangsuProfile")}}</div>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
            <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview
                                            src="../../assets/img/service/page3-5.png"></div>
            <div class="name" :class="$i18n.locale === 'en' ? 'en-name' : ''">{{$t("Service.Integration.Chongqing")}}</div>
            <div class="desc" :class="$i18n.locale === 'en' ? 'en-desc' : ''">{{$t("Service.Integration.ChongqingProfile")}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 4" class="container">
      <div class="product-item-title">{{$t("Service.Delivery.Overview")}}</div>
      <div class="page-text mb-15" v-animate-on-intersect>{{$t("Service.Delivery.ProfileOne")}}
        <br>{{$t("Service.Delivery.ProfileTwo")}}
        <br>{{$t("Service.Delivery.ProfileThree")}}
      </div>
      <div v-animate-on-intersect>
        <el-row class="flex-warp mb-40 radius-10 box-shadow" justify="space-between" type="flex">
          <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
            <div class="preview-image"><img class="box-img" style="height: 100%" v-image-preview
                                            src="../../assets/img/service/page4-1.png"></div>
          </el-col>
          <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
            <div class="dot-box">
              <div class="dot-list f-bold">{{$t("Service.Delivery.Typical")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.Training")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.Inspection")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.Installation")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.DCS")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.Commissioning")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.Cooperate")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.Regular")}}</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">{{$t("Service.Delivery.Incident")}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "我们的服务详情",
  data() {
    return {
      menuType: 1
    }
  },
  mounted() {
    if (this.$route.query.type) this.menuType = this.$route.query.type
  },
  beforeRouteEnter(to, from, next) {
    let _that = this
    next(vm => {
      // 在路由进入前滚动到顶部
      window.scrollTo(0, 0);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.type) this.menuType = to.query.type
    // 在当前路由改变，但是该组件被复用时滚动到顶部
    this.scrollToTop();
    next();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
