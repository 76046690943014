import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        // name: 'Home',
        component: () => import( '../views/Home.vue'),
        meta: {
            title: '重庆敏恒科技 | 专注熔融结晶|VC熔融结晶|FEC熔融结晶',
            enTitle: 'CHONG QING MINHRNG TECHNOLOGY.CO.,LTD | Focus on Melting crystallization | VC Melting crystallization | FEC Melting crystallization'
        }
    },
    {
        path: '/product',
        // name: 'Product',
        component: () => import( '../views/product/index.vue'),
        meta: {
            title: '我们的产品 | 重庆敏恒科技',
            enTitle:'Our Products | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/product/page-rrjj',
        // name: '熔融结晶',
        component: () => import( '../views/product/page-rrjj.vue'),
        meta: {
            title: '熔融结晶技术 | 重庆敏恒科技',
            enTitle:'Melting crystallization technology | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/product/page-shjj',
        // name: '升华结晶',
        component: () => import( '../views/product/page-shjj.vue'),
        meta: {title: '升华结晶技术 | 重庆敏恒科技',
            enTitle:'Sublimation crystallization technology | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/product/page-fzzl',
        // name: '分子蒸馏',
        component: () => import( '../views/product/page-fzzl.vue'),
        meta: {title: '分子蒸馏技术 | 重庆敏恒科技',
            enTitle:'Molecular distillation | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/product/page-qqsysw',
        // name: '氢氰酸衍生物系列产品',
        component: () => import( '../views/product/page-qqsysw.vue'),
        meta: {title: '氢氰酸衍生物系列产品 | 重庆敏恒科技',
            enTitle:'Hydrocyanic acid derivative series of products | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/product/page-ldctjj',
        // name: '锂电池添加剂',
        component: () => import( '../views/product/page-ldctjj.vue'),
        meta: {title: '锂电池添加剂 | 重庆敏恒科技',
            enTitle:'Lithium battery additives | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/product/page-sfhfczyhly',
        // name: '三废或副产资源化利用系列产品',
        component: () => import( '../views/product/page-sfhfczyhly.vue'),
        meta: {title: '三废或副产资源化利用系列产品 | 重庆敏恒科技',
            enTitle:'Three waste or by-product resource utilization series of products | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/service',
        // name: '我们的服务',
        component: () => import( '../views/service/index.vue'),
        meta: {title: '我们的服务 | 重庆敏恒科技',
            enTitle:'Our Services | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/service/page-detail',
        // name: '我们的服务详情',
        component: () => import( '../views/service/page-detail.vue'),
        meta: {title: '我们的服务 | 重庆敏恒科技',
            enTitle:'Our services | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/news',
        // name: '资讯',
        component: () => import( '../views/News.vue'),
        meta: {title: '资讯 | 重庆敏恒科技',
            enTitle:'News | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    },
    {
        path: '/aboutUs',
        // name: '关于我们',
        component: () => import( '../views/AboutUs.vue'),
        meta: {title: '关于我们 | 重庆敏恒科技',
            enTitle:'About Us | CHONG QING MINHRNG TECHNOLOGY.CO.,LTD'}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
